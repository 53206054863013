import Api from './Api'

export default {
    getUserChatRooms(userId) {
        return Api().get(`/getUserChatrooms/${userId}`);
    },
    getChatroomMessagesByReceiverId(receiverId) {
        return Api().get(`/getChatroomMessagesByReceiverId/${receiverId}`)
    },
    getChatroom(users) {
        return Api().post('/getChatroom', users)
    },
    getChatroomMessages(details) {
        return Api().get(`/getChatroomMessages/${details.chatroomId}/${details.page}/${details.order}`)
    },
    markChatroomAsRead(userId, chatroomId) {
        return Api().post(`/markChatroomAsRead/user/${userId}/${chatroomId}`)
    },
    getUnreadChatroomCounter() {
        return Api().get('/getUnreadChatroomCounter')
    }
}
