/**
 * DOcumentation at https://github.com/simple-notify/simple-notify
 */
import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.min.css'

export default {
    displayWarning(title, msg, timeout = 3000, autoClose = true ) {
        new Notify({
            status: 'warning',
            title: title ? title :'An Error Occurred',
            text: msg,
            effect: 'fade',
            speed: 300,
            customClass: null,
            customIcon: null,
            showIcon: true,
            showCloseButton: true,
            autoclose: autoClose,
            autotimeout: timeout,
            gap: 20,
            distance: 20,
            type: 1,
            position: 'right bottom'
        })
    },
    displayError(title, msg, timeout = 3000, autoClose = true ) {
        new Notify({
            status: 'error',
            title: title ? title :'An Error Occurred',
            text: msg,
            effect: 'fade',
            speed: 300,
            customClass: null,
            customIcon: null,
            showIcon: true,
            showCloseButton: true,
            autoclose: autoClose,
            autotimeout: timeout,
            gap: 20,
            distance: 20,
            type: 1,
            position: 'right bottom'
        })
    },
    displaySuccess(title, msg, timeout = 3000, autoClose = true ) {
        new Notify({
            status: 'success',
            title: title ? title :'Success!',
            text: msg,
            effect: 'fade',
            speed: 300,
            customClass: null,
            customIcon: null,
            showIcon: true,
            showCloseButton: true,
            autoclose: autoClose,
            autotimeout: timeout,
            gap: 20,
            distance: 20,
            type: 1,
            position: 'right bottom'
        })
    },
    displayInfo(title, msg, timeout = 3000, autoClose = true) {
        new Notify({
            status: 'info',
            title: title ? title :'Info',
            text: msg,
            effect: 'fade',
            speed: 300,
            customClass: null,
            customIcon: null,
            showIcon: true,
            showCloseButton: true,
            autoclose: autoClose,
            autotimeout: timeout,
            gap: 20,
            distance: 20,
            type: 1,
            position: 'right bottom'
        })
    },
}