import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export const store = createStore({
    // Keep state persistent
    plugins: [
        createPersistedState()
    ],
    state: {
        count: 1,
        user: {
            id: null,
            // username: user.username,
            email: null,
            firstName: null,
            lastName: null,
            homeAddress: null,
            lat: null,
            lng: null,
            roles: [],
            accessToken: null,
            refreshToken: null,
            profileImage: null,
            profileImageMeta: null,
            phone: null,
            services: null,
            activated: null,
            validated: null,
            subscription: {
                stripeCustomerId: null,
                checkoutPackage: null
            },
            totalRating: null,
            hasActiveSubscription: null,
            remainingBonusTasks: null
        },
        currentUser: null,
        isUserLoggedIn: false,
        chat: {
            receiverId: null,
            chatRoomId: null,
            name: null
        },
        app: {
            pageTitle: null,
            pagePrevious: null,
            categoriesServices: {
                data: null,
                timeUpdated: null
            },
            menuTabSelection: {
                userInstant: null,
                userOffers: null,
                expertInstant: null,
                expertOffers: null
            }
        },
        isMobile: null,
        counters: {
            notificationsCount: null,
            unreadChatroomsCount: null,
            unreadTaskRequestsCount: null,
            unreadOffersCount: null
        },
        locale: null,
        fcmToken: null
    },
    getters: {
        getUser(state) {
            return state.user !== null ? state.user : null;
        },
        getUserRoles(state) {
            return state.user !== null ? state.user.roles : null;
        },
        getCategoriesServices(state) {
            return state.app.categoriesServices !== null ? state.app.categoriesServices : null;
        },
        getLocale(state) {
            return state.locale !== null ? state.locale : null;
        },
        getMenuTabSelection: (state) => (tabName) => {
            return state.app.menuTabSelection[tabName];
        },
        getFcmToken(state) {
            return state.fcmToken;
        }
    },
    mutations: {
        setToken(state, token) {
            state.user.accessToken = token;
            state.isUserLoggedIn = !!(token);
        },
        setUser(state, user) {
            state.user = user;
        },
        setCurrentUser(state, user) {
            state.currentUser = user;
        },
        setCategoriesServices(state, data) {
            state.app.categoriesServices.data = data;
            state.app.categoriesServices.timeUpdated = new Date().getTime();
        },
        setProfileImage(state, image) {
            state.user.profileImage = image;
        },
        setUserDetails(state, details) {
            state.user.firstName = details.firstName;
            state.user.lastName = details.lastName;
            state.user.phone = details.phone;
            state.user.serviceDescription = details.serviceDescription;
        },
        setLatLng(state, latLng) {
            state.user.lat = latLng.lat;
            state.user.lng = latLng.lng;
            if (typeof latLng.label !== 'undefined') {
                state.user.homeAddress = latLng.label;
            }
        },
        setUserFavourites(state, data) {
            if (data.action == 'remove') {
                let index = state.user.userFavourites.map(e => e.providerId).indexOf(data.providerId);
                state.user.userFavourites.splice(index, 1);
            } else {
                state.user.userFavourites.push({userId: data.userId, providerId: data.providerId});
            }
        },
        setNotificationsCount(state, count) {
            state.counters.notificationsCount = count;
        },
        setUnreadChatroomsCount(state, count) {
            state.counters.unreadChatroomsCount = count;
        },
        setPageTitle(state, pageTitle) {
            state.app.pageTitle = pageTitle;
        },
        setPagePrevious(state, routeName) {
            state.app.pagePrevious = routeName;
        },
        setChatRoom(state, data) {
            state.chat.chatRoomId = data.chatRoomId !== undefined ? data.chatRoomId : state.chat.chatRoomId;
            state.chat.receiverId = data.receiverId !== undefined ? data.receiverId : state.chat.receiverId;
            state.chat.name = data.name !== undefined ? data.name : state.chat.name;
        },
        setIsMobile(state, isMobile) {
            state.isMobile = isMobile;
        },
        setLocale(state, locale){
            state.locale = locale;
        },
        setUserActivatedStatus(state, status) {
            state.user.activated = status;
        },
        setUserValidatedStatus(state, status) {
            state.user.validated = status;
        },
        setCheckoutPackage(state, chackoutPackage) {
            state.user.subscription.checkoutPackage = chackoutPackage;
        },
        setUnreadTaskRequestsCount(state, count) {
            state.counters.unreadTaskRequestsCount = count;
        },
        setUnreadOffersCount(state, count) {
            state.counters.unreadOffersCount = count;
        },
        setMenuTabSelection(state, details) {
            state.app.menuTabSelection[details.tabName] = details.tabIndex;
        },
        updateUserStateByKeys(state, keys) {
            for(const[key, value] of Object.entries(keys)) {
                state.user[key] = value;
            }
        },
        setFcmToken(state, token) {
            state.fcmToken = token;
        },

    },
    actions: {
        setToken({commit}, token) {
            commit('setToken', token);
        },
        setUser({commit}, user) {
            commit('setUser', user);
        },
        setCurrentUser({commit}, user) {
            commit('setCurrentUser', user);
        },
        setCategoriesServices({commit}, data) {
            commit('setCategoriesServices', data);
        },
        setProfileImage({commit}, image) {
            commit('setProfileImage', image);
        },
        setUserDetails({commit}, details) {
            commit('setUserDetails', details);
        },
        setLatLng({commit}, latLng) {
            commit('setLatLng', latLng);
        },
        setHomeAddress({commit}, homeAddress) {
            commit('setHomeAddress', homeAddress);
        },
        setUserFavourites({commit}, data) {
            commit('setUserFavourites', data);
        },
        setNotificationsCount({commit}, count) {
            commit('setNotificationsCount', count)
        },
        setUnreadChatroomsCount({commit}, count) {
            commit('setUnreadChatroomsCount', count)
        },
        setUnreadTaskRequestsCount({commit}, count) {
            commit('setUnreadTaskRequestsCount', count)
        },
        setUnreadOffersCount({commit}, count) {
            commit('setUnreadOffersCount', count)
        },
        setPageTitle({commit}, pageTitle) {
            commit('setPageTitle', pageTitle)
        },
        setPagePrevious({commit}, routeName) {
            commit('setPagePrevious', routeName)
        },
        setChatRoom({commit}, data) {
            commit('setChatRoom', data)
        },
        setIsMobile({commit}, data) {
            commit('setIsMobile', data)
        },
        setLocale({commit}, locale) {
            commit('setLocale', locale)
        },
        setUserActivatedStatus({commit}, status) {
            commit('setUserActivatedStatus', status);
        },
        setUserValidatedStatus({commit}, status) {
            commit('setUserValidatedStatus', status);
        },
        setCheckoutPackage({commit}, chackoutPackage) {
            commit('setCheckoutPackage', chackoutPackage)
        },
        setMenuTabSelection({commit}, menuSelection) {
            commit('setMenuTabSelection', menuSelection)
        },
        updateUserStateByKeys({commit}, keys) {
            commit('updateUserStateByKeys', keys)
        },
        setFcmToken({commit}, token) {
            commit('setFcmToken', token)
        }
    }
})