import platform from 'platform-detect'
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { store } from '@/store'
import EventBus from "@/helpers/EventBus";
const config = require('../../config');
const targetDate = new Date(config.LAUNCH_DATE).getTime();

/* ##### NOTE-SOS:  ################################################
#                                                                 #
#  turn platform.packaged to true to simulate mobile app behaviour #
#                                                                  #
###################################################################*/
// platform.packaged = true;
// const beforeEnter = (to, from, next) => {
//   const hasToken = Boolean(localStorage.getItem('token'));
//   if (!hasToken) {
//     return next({ path: 'login' });
//   }
//   next();
// }

const routes = [
  // Non layout Routes
  {
    path: '/mobile-login',
    name: 'mobile-login',
    component: () => import('../views/mobile/LoginPage.vue')
  },
  {
    path: '/actions-response',
    name: 'actions-response',
    props: true,
    component: () => import('../components/SuccessErrorPage.vue'),
    beforeEnter: (to, from) => {
      store.dispatch('setPagePrevious', from.path);
    },
  },
  // Admin Routes
  {
    path: '/admin',
    component: () => import('../layouts/admin/MainLayout.vue'),
    children: [
      {
        path: '/admin',
        name: 'admin-dashboard',
        component: () => import('../views/admin/Dashboard.vue'),
        meta: {
          permissions: true,
          role: ['ROLE_ADMIN']
        }
      },
      {
        path: '/admin/users',
        name: 'admin-users',
        component: () => import('../views/admin/Users.vue'),
        meta: {
          permissions: true,
          role: ['ROLE_ADMIN']
        }
      },
      {
        path: '/admin/categories',
        name: 'admin-categories',
        component: () => import('../views/admin/Categories.vue'),
        meta: {
          permissions: true,
          role: ['ROLE_ADMIN']
        }
      },
      {
        path: '/admin/server-logs',
        name: 'admin-server-logs',
        component: () => import('../views/admin/ServerLogs.vue'),
        meta: {
          permissions: true,
          role: ['ROLE_ADMIN']
        }
      },
      {
        path: '/admin/reported-users',
        name: 'admin-reported-users',
        component: () => import('../views/admin/ReportedUsers.vue'),
        meta: {
          permissions: true,
          role: ['ROLE_ADMIN']
        }
      },
      {
        path: '/admin/tasks',
        name: 'admin-tasks',
        component: () => import('../views/admin/Tasks.vue'),
        meta: {
          permissions: true,
          role: ['ROLE_ADMIN']
        }
      },
      {
        path: '/admin/services',
        name: 'admin-services',
        component: () => import('../views/admin/Services.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          permissions: true,
          role: ['ROLE_ADMIN']
        }
      },
      {
        path: '/admin/service-experts/:serviceId',
        name: 'admin-service-experts',
        component: () => import('../views/admin/ServiceExperts.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          permissions: true,
          role: ['ROLE_ADMIN']
        }
      },
    ]
  },
  // App layout Routes
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'main',
        component: () => import('../views/MainView.vue'),
        beforeEnter: (to, from) => {

          store.dispatch('setPagePrevious', null);
          // reject the navigation
          var user = store.state.user;
          if(platform.packaged && !user) {
            router.push({ name: 'mobile-login'});
            return;
          }
          if(user && (user.roles.includes('ROLE_EXPERT') || user.roles.includes('ROLE_USER')) && user.activated == false)
          {
            router.push({ name: 'account-activation'});
            return;
          }
          if(user && (user.roles.includes('ROLE_ADMIN')))
          {
            router.push({ path: '/admin'});
            return;
          }
          // if(user && user.roles.includes('ROLE_EXPERT') && user.validated == false)
          // {
          //   router.push({ name: 'KYC-verification'});
          // }
        },
        meta: {
          isLoginRequired: platform.packaged ? true : false
        }
      },
       {
        path: '/account-deletion',
        name: 'account-deletion',
        component: () => import('../components/auth/AccountDeletion.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Delete your account',
          permissions: true,
          role: ['ROLE_USER','ROLE_ADMIN','ROLE_EXPERT']
        }
      },
      {
        path: '/user-profile',
        name: 'user-profile',
        component: () => import('../views/UserProfile.vue'),
        meta: {
          pageTitle: 'My account',
          permissions: true,
          role: ['ROLE_USER','ROLE_ADMIN','ROLE_EXPERT']
        }
      },
      {
        path: '/edit-services',
        name: 'edit-services',
        component: () => import('../views/User/EditServices.vue'),
        meta: {
          pageTitle: 'My account',
          permissions: true,
          role: ['ROLE_ADMIN','ROLE_EXPERT']
        }
      },
      {
        path: '/all-services',
        name: 'all-services',
        component: () => import('../views/AllServices.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
      },
      {
        path: '/services/:categoryName/:categoryId',
        name: 'services',
        component: () => import('../views/Services.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Service Selection'
        }
      },
      {
        path: '/add-task/:type?/:serviceId?',
        name: 'add-task',
        component: () => import('../views/AddTask.vue'),
        meta: {
          pageTitle: 'Add Task',
          isLoginRequired: false,
          allowGuest:true,
          permissions: true,
          role: ['ROLE_USER'],
          params: true
        }
      },
      {
        path: '/edit-task/:taskId',
        name: 'edit-task',
        component: () => import('../views/AddTask.vue'),
        meta: {
          pageTitle: 'Edit Task',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER']
        }
      },
      { // Deprecated
        path: '/expert-search/serviceId-:serviceId/taskId-:taskId',
        name: 'expert-search',
        component: () => import('../views/ExpertSearch.vue'),
        meta: {
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER'],
        }
      },
      {
        path: '/expert-search-new/serviceId-:serviceId/taskId-:taskId',
        name: 'expert-search-new',
        component: () => import('../views/ExpertSearchNew.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER'],
        }
      },
      {
        path: '/expert-details/:expertId',
        name: 'expert-details',
        component: () => import('../views/ExpertDetails.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER']
        }
      },
      {
        path: '/user-favourites',
        name: 'user-favourites',
        component: () => import('../views/User/Favourites.vue'),
        meta: {
          pageTitle: 'Favourites',
          isLoginRequired: true,
          role: ['ROLE_USER']
        }
      },
      {
        path: '/user-notifications',
        name: 'user-notifications',
        component: () => import('../views/User/Notifications.vue'),
        meta: {
          pageTitle: 'Notifications',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER', 'ROLE_EXPERT']
        }
      },
      {
        path: '/user-chat',
        name: 'user-chat',
        component: () => import('../views/User/Chat.vue'),
        meta: {
          pageTitle: 'Chat',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER', 'ROLE_EXPERT']
        },
      },
      {
        path: '/chat-message',
        name: 'chat-message',
        component: () => import('../views/User/ChatMessage.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Chat Message',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER', 'ROLE_EXPERT']
        },
      },
      // {
      //   path: '/my-tasks',
      //   name: 'user-tasks',
      //   component: () => import('../views/User/Tasks.vue'),
      //   meta: {
      //     pageTitle: 'Tasks',
      //     isLoginRequired: true,
      //     permissions: true,
      //     role: ['ROLE_USER', 'ROLE_EXPERT']
      //   }
      // },
      {
        path: '/user-offers',
        name: 'user-offers',
        component: () => import('../views/User/Offers.vue'),
        meta: {
          pageTitle: 'My Offers',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER']
        }
      },
      {
        path: '/offers-page/:taskId',
        name: 'offers-page',
        component: () => import('../views/OffersPage.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER']
        }
      },
      {
        path: '/expert-offers',
        name: 'expert-offers',
        component: () => import('../views/Expert/Offers.vue'),
        meta: {
          pageTitle: 'My Offers',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_EXPERT']
        }
      },
      {
        path: '/expert-news',
        name: 'expert-news',
        component: () => import('../views/User/ExpertNews.vue'),
        meta: {
          pageTitle: 'News',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_EXPERT']
        }
      },
      {
        path: '/expert-statistics',
        name: 'expert-statistics',
        component: () => import('../views/Expert/Statistics.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Statistics',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_EXPERT']
        }
      },
      {
        path: '/place-offer/:taskId',
        name: 'place-offer',
        component: () => import('../views/PlaceOffer.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Place Offer',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_EXPERT']
        }
      },
      {
        path: '/edit-offer/:offerId',
        name: 'edit-offer',
        component: () => import('../views/PlaceOffer.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Edit Offer',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_EXPERT'],
          edit: true
        }
      },
      {
        path: '/task/:taskId',
        name: 'task-page',
        component: () => import('../views/TaskPage.vue'),
        beforeEnter: (to, from) => {
          from.name == 'actions-response' ? store.dispatch('setPagePrevious', null) : store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER', 'ROLE_EXPERT', 'ROLE_ADMIN']
        }
      },
      {
        path: '/register-professional',
        name: 'register-professional',
        component: () => import('../components/auth/RegisterProfessional.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Join as a professional'
        }
      },
      {
        path: '/expert-dashboard',
        name: 'expert-dashboard',
        component: () => import('../views/expertDashboard.vue'),
        beforeEnter: (to, from) => {
          // reject the navigation
          var user = store.state.user;
          if(user && (user.roles.includes('ROLE_EXPERT') || user.roles.includes('ROLE_USER')) && user.activated == false) 
          {
            router.push({ name: 'account-activation'});
            return;
          }
          if(user && user.roles.includes('ROLE_EXPERT') && user.validated == false) 
          {
            router.push({ name: 'prof-verification'});
          }
        },
        meta: {
          pageTitle: 'Dashboard',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_EXPERT']
        }
      },
      {
        path: '/expert-cancel-task/:taskId',
        name: 'expert-cancel-task',
        component: () => import('../views/ExpertTaskCancellation.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Cancel Task',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER','ROLE_EXPERT']
        }
      },
      // TODO : UNCOMMENT LATER
      // {
      //   path: '/expert-subscription',
      //   name: 'expert-subscription',
      //   component: () => import('../views/Expert/Subscription.vue'),
      //   meta: {
      //     pageTitle: 'Subscription',
      //     isLoginRequired: true,
      //     permissions: true,
      //     role: ['ROLE_EXPERT']
      //   }
      // },
      {
        path: '/expert-checkout',
        name: 'expert-checkout',
        component: () => import('../views/Expert/Checkout.vue'),
        meta: {
          pageTitle: 'Checkout',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_EXPERT']
        }
      },
      {
        path: '/user-dashboard',
        name: 'user-dashboard',
        component: () => import('../views/User/UserDashboard.vue'),
        meta: {
          pageTitle: 'Dashboard',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER']
        }
      },
      {
        path: '/user-reviews',
        name: 'user-reviews',
        component: () => import('../views/User/Reviews.vue'),
        meta: {
          pageTitle: 'Reviews',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER', 'ROLE_EXPERT']
        },
      },
      {
        path: 'task/review/:taskId/:userId',
        name: 'review',
        component: () => import('../views/User/ReviewUser.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Review User',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER', 'ROLE_EXPERT']
        },
      },
      {
        path: 'tasks-history/:taskId?/:taskTitle?',
        name: 'tasks-history',
        component: () => import('../views/User/TasksHistory.vue'),
        beforeEnter: (to, from) => {
          if(to.params.taskId !== '') {
            store.dispatch('setPagePrevious', from.path);
          }
        },
        meta: {
          pageTitle: 'History',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER', 'ROLE_EXPERT']
        },
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../components/auth/ForgotPassword.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Forgot Password'
        }
      },
      {
        path: '/reset-password/:token/:userId',
        name: 'reset-password',
        component: () => import('../components/auth/ResetPassword.vue'),
        meta: {
          pageTitle: 'Reset Password'
        }
      },
      {
        path: '/verify-email/:token/:email',
        name: 'verify-email',
        component: () => import('../components/auth/VerifyEmail.vue'),
        meta: {
          pageTitle: 'Verify Email'
        }
      },
      {
        path: '/account-activation',
        name: 'account-activation',
        component: () => import('../components/auth/AccountActivation.vue'),
        meta: {
          pageTitle: 'Account Activation'
        }
      },
      // {
      //   path: '/KYC-verification',
      //   name: 'KYC-verification',
      //   component: () => import('../components/auth/KYC.vue'),
      //   meta: {
      //     pageTitle: 'KYC Verification'
      //   }
      // },
      {
        path: '/prof-verification',
        name: 'prof-verification',
        component: () => import('../components/auth/ExpertValidation.vue'),
        meta: {
          pageTitle: 'Account Activation'
        }
      },
      {
        path: '/report-user/:reportedUserId/:taskId',
        name: 'report-user',
        component: () => import('../views/ReportUser.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Report User',
          isLoginRequired: true,
          permissions: true,
          role: ['ROLE_USER','ROLE_EXPERT']
        }
      },
      // QUICK LINKS
      {
        path: '/faq',
        name: 'faq',
        component: () => import('../views/quickLinks/Faq.vue')
      },
      {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('../views/quickLinks/ContactUs.vue')
      },
      // Mobile
      {
        path: '/mobile-categories',
        name: 'mobile-categories',
        component: () => import('../views/mobile/Categories.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Category Selection'
        }
      },
      {
        path: '/mobile-user-register',
        name: 'mobile-user-register',
        component: () => import('../views/mobile/UserRegister.vue'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
        meta: {
          pageTitle: 'Register'
        }
      },
      {
        path:'/page/:slug',
        name:'page',
        component: () => import('../views/StaticPage'),
        beforeEnter: (to, from) => {
          store.dispatch('setPagePrevious', from.path);
        },
      },
      // Launch Aleret 
      {
        path: '/launch-alert',
        name: 'launch-alert',
        component: () => import('../views/LaunchAlert.vue'),
        // beforeEnter: (to, from) => {
        //   store.dispatch('setPagePrevious', from.path);
        // },
        // meta: {
        //   pageTitle: 'Coming Soon'
        // }
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: to => {
          return '/';
        }
      }
    ]
  }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

//Router Guard
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  var proceedNext = false;
  // Logged in required route
   if (to.matched.some(record => record.meta.isLoginRequired)) {
      var user = store.getters.getUser;
      if(user.id && (user.roles.includes('ROLE_EXPERT') || user.roles.includes('ROLE_USER')) && user.activated == false) {
        next({ name: 'account-activation' })
        return;
      }
      // Non validated expert redirection to KYC page
      // if(user.id && user.roles.includes('ROLE_EXPERT') && user.validated == false) {
      //   next({ name: 'KYC-verification' })
      //   return;
      // }
      if(user.id && user.roles.includes('ROLE_EXPERT') && user.validated == false) {
        next({ name: 'prof-verification' })
        return;
      }
      // TEMPORARY GUARD : UNTIL OFFICIAL LAUNCH
      if(user.activated == true && (new Date().getTime() < targetDate)) {
        next({ name: 'launch-alert' })
        return;
      }
      if(user.id !==null) {
        proceedNext = true;
      } else {
        if(platform.packaged) {
          console.log('IS PACKAGED???????? ', platform.packaged)
          next({ name: 'mobile-login' })
          return
        }
        EventBus.$emit("OPEN_LOGIN_POPUP", {nextPath: to.fullPath, nextPathName: to.name, nextPathParams: to.params, usingParams: to.meta.params});
        return;
      }
    } 
   // Role Based Route Permission
   if (to.matched.some(record => record.meta.permissions)) {
      var userRole = store.getters.getUserRoles;
      const matchingRoles = userRole.filter((obj) => to.meta.role.indexOf(obj) !== -1);
      const allowGuests = to.meta && to.meta.allowGuest;
      // redirect to main if no matching permissions
      if(matchingRoles.length == 0 && !allowGuests) {
        store.dispatch('setPageTitle', to.meta.pageTitle);
        next({ name: 'main' })
        return
      } else {
        proceedNext = true;
      }
    } 
    else {
      store.dispatch('setPageTitle', to.meta.pageTitle);
      next() // does not require auth, make sure to always call next()!
      return
    }

    if(proceedNext) {
      store.dispatch('setPageTitle', to.meta.pageTitle);
      store.dispatch('setPagePrevious', null);
      next();
    }
});

export default router
