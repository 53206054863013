<template>
  <ul v-if="$store.state.user.id !== null" class="nav header-navbar-rht">
<!--    <li v-if="!$isMobile()"><LanguagePicker></LanguagePicker></li>-->

    <li v-if="$store.state.user.roles.includes('ROLE_EXPERT')" class="nav-item logged-item navbar-chat">
      <a role="button" class="nav-link" @click="$store.dispatch('setMenuTabSelection', { tabName: 'expertInstant', tabIndex: 'tab1' }), $router.push({ name: 'expert-dashboard' })">
        <i class="fas fa-bolt"></i> <span v-if="$store.state.counters.unreadTaskRequestsCount > 0" class="badge badge-pill bg-yellow pulsate">{{ $store.state.counters.unreadTaskRequestsCount }}</span>
      </a>
    </li>
    <!-- Notifications -->
    <Notification />
    <!-- /Notifications -->

    <!-- Chat -->
    <li class="nav-item logged-item navbar-chat">
      <router-link to="/user-chat">
        <a class="nav-link">
          <i class="fas fa-comments"></i> <span v-if="$store.state.counters.unreadChatroomsCount > 0" class="badge badge-pill bg-yellow pulsate">{{ $store.state.counters.unreadChatroomsCount }}</span>
        </a>
      </router-link>
    </li>
    <!-- /Chat -->

    <!-- User Menu -->
    <li class="nav-item dropdown has-arrow logged-item">
      <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="user-img">
          <i v-if="$store.state.user.profileImage == null" class="avatar-md fa fa-user-circle"></i>
          <img v-else class="rounded-circle" :src="$store.state.user.profileImage" alt="" width="31" />
        </span>
      </a>
      <!-- ROLE: USER -->
      <div v-if="$store.state.user.roles.includes('ROLE_USER')" :class="`dropdown-menu dropdown-menu-end ${$isMobile() ? 'dropdown-menu-mobile' : ''}`">
        <div class="user-header">
          <a v-if="$isMobile()" id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
          <div class="avatar avatar-sm">
            <i v-if="$store.state.user.profileImage == null" class="avatar-md fa fa-user-circle"></i>
            <img v-else class="rounded-circle" :src="$store.state.user.profileImage" alt="" width="31" />
          </div>
          <div class="user-text">
            <h6 class="text-truncate">{{ $store.state.user.firstName }} {{ $store.state.user.lastName }}</h6>
            <!--            <p class="text-muted mb-0">Provider</p>-->
          </div>
        </div>
        <router-link class="dropdown-item" to="/user-profile"><i class="fa fa-user"></i>{{ this.$t("userMenu.profileSettings") }}</router-link>
        <router-link class="dropdown-item" to="/user-favourites"><i class="fas fa-heart"></i>{{ this.$t("userMenu.favorites") }}</router-link>
        <router-link class="dropdown-item" to="/user-reviews"><i class="fas fa-star"></i>{{ this.$t("userMenu.reviews") }}</router-link>
        <router-link class="dropdown-item" :to="{name: 'account-deletion'}"><i class="fas fa-user-times"></i>{{ this.$t('accountDeletion.deleteAccount') }}</router-link>
        <a v-if="$store.state.currentUser == null" role="button" @click="logout()" class="dropdown-item"><i class="fas fa-sign-out-alt"></i>{{ this.$t("userMenu.logout") }}</a>
        <a v-else @click="stopImpersonate()" role="button" class="dropdown-item"><i class="far fa-stop-circle stop-impersonate"></i>{{ this.$t("userMenu.logout") }}</a>
      </div>
      <!-- /ROLE: USER -->

      <!-- ROLE: EXPERT -->
      <div v-if="$store.state.user.roles.includes('ROLE_EXPERT')" :class="`dropdown-menu dropdown-menu-end ${$isMobile() ? 'dropdown-menu-mobile' : ''}`">
        <div class="user-header">
          <a v-if="$isMobile()" id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
          <div class="avatar avatar-sm">
            <i v-if="$store.state.user.profileImage == null" class="avatar-md fa fa-user-circle"></i>
            <img v-else class="rounded-circle" :src="$store.state.user.profileImage" alt="" width="31" />
          </div>
          <div class="user-text">
            <h6 class="text-truncate">{{ $store.state.user.firstName }} {{ $store.state.user.lastName }}</h6>
            <!-- <p class="text-muted mb-0">Provider</p> -->
          </div>
        </div>
        <router-link class="dropdown-item" to="/user-profile"><i class="fa fa-user"></i>{{ this.$t("userMenu.profileSettings") }}</router-link>
        <router-link class="dropdown-item" to="/tasks-history"><i class="fas fa-history"></i>{{ this.$t("userMenu.tasksHistory") }}</router-link>
        <router-link class="dropdown-item" to="/user-reviews"><i class="fas fa-star"></i>{{ this.$t("userMenu.reviews") }}</router-link>
        <!-- <router-link class="dropdown-item" to="/expert-subscription"><i class="fa fa-credit-card"></i>{{ this.$t("userMenu.subscription") }}</router-link> -->
        <router-link class="dropdown-item" to="/expert-statistics"><i class="fas fa-chart-pie"></i>{{ this.$t("userMenu.statistics") }}</router-link>
        <router-link class="dropdown-item" :to="{name: 'account-deletion'}"><i class="fas fa-user-times"></i>{{ this.$t('accountDeletion.deleteAccount') }}</router-link>
        <a v-if="$store.state.currentUser == null" role="button" @click="logout()" class="dropdown-item"><i class="fas fa-sign-out-alt"></i>{{ this.$t("userMenu.logout") }}</a>
        <a v-else @click="stopImpersonate()" role="button" class="dropdown-item"><i class="far fa-stop-circle stop-impersonate"></i>{{ this.$t("userMenu.logout") }}</a>
      </div>
      <!-- /ROLE: EXPERT -->
    </li>
    <!-- /User Menu -->
  </ul>
</template>
<style scoped>
.header-navbar-rht {
  position: absolute;
  right: 0;
}
</style>
<script>
import AuthenticationService from "@/services/AuthenticationService";
import helpers from "@/helpers/helpers";
import Notification from "@/layouts/Notification";
import FloatingActionButton from "@/components/FloatingActionButton";
import LanguagePicker from "@/components/LanguagePicker";

export default {
  name: "LoggedNavbar",
  components: {
    Notification,
    LanguagePicker,
  },
  methods: {
    async logout() {
      helpers.logout();
      // try {
      //   var ref = this;
      //   await AuthenticationService.signout().then((resp)=> {
      //     ref.$store.dispatch('setToken', null)
      //     ref.$store.dispatch('setUser', null)
      //     ref.$router.push({
      //       path: '/'
      //     });
      //   });
      // }
      // catch(error) {
      //   console.log('error', error)
      // }
    },
    stopImpersonate() {
      helpers.stopImpersonate();
    }
  },
};
</script>
<style scoped>
.dropdown-menu-mobile {
  top: 79% !important;
}
.dropdown-menu-mobile {
  position: absolute;
  top: 0 !important;
  right: -10px !important;
  min-width: 103vw !important;
  /* padding: 20px !important; */
  /* padding-top: 10px !important; */
  /* background-color: var(--theme-color); */
}
.dropdown-menu-mobile a {
  /* color: white !important; */
  /* border-top: none !important; */
  padding: 20px 15px !important;
}
.dropdown-menu-mobile a:last-child {
  /* position: absolute;
  bottom: 20%; */
}
.dropdown-menu-mobile .user-header {
  margin-top: 5px;
  /* background-color: var(--theme-color); */
  /* margin-bottom: 29%; */
}
.dropdown-menu-mobile .text-truncate {
  /* color: white; */
}
.dropdown-menu i {
  padding-right: 5px;
}
.dropdown-menu a {
  color: #464646 !important;
  /* color: #858585 !important; */
}
.dropdown-menu-mobile #menu_close {
  position: absolute;
  right: 14px;
  top: 0;
}
.dropdown-menu-mobile .user-text {
  margin-top: 7px;
}
.rounded-circle {
  vertical-align: unset;
}
.stop-impersonate {
  color: red;
  font-size: 22px;
  cursor: pointer;
}
</style>

