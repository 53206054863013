// index.js
import {createI18n} from 'vue-i18n'
import { Preferences } from '@capacitor/preferences';
import en from './en.json'
import el from './el.json'

export const browserLocale =  window.navigator.language.split('-')[0];
let availableLocales = getAvailableLocales();
function loadLocaleMessages() {
    var locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i);

    var messages = {};
    locales.keys().forEach(function (key) {

        var matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            var locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function getAvailableLocales() {
    let availableLocales = [];
    var locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    locales.keys().forEach(function (key) {

        var matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            var locale = matched[1];
            availableLocales.push(locale);
        }
    });
    return availableLocales;
}
let startLocale  = 'el';
    //= availableLocales.includes(browserLocale) ? browserLocale : 'el';
const storedLocale = localStorage.getItem('language');
if(storedLocale && availableLocales.includes(storedLocale)){
    startLocale = storedLocale;
}

const i18n = createI18n({
    locale: startLocale,
    fallbackLocale: 'el',
    messages: loadLocaleMessages()
});
//console.log(availableLocales, startLocale);

const getLanguage = async () => {
    const { value } = await Preferences.get({ key: 'lang' });
    if(value) {
        i18n.global.locale = value;
    }
    //console.log(`Language Value:  ${value}!`);
};
getLanguage();

export default i18n;
export const locales = availableLocales;
export const defaultLocale = startLocale;

export const checkLanguage =  async () => {
    const { lang } = await Preferences.get({ key: 'lang' });
    if(lang && availableLocales.includes(lang)) {
        localStorage.getItem('language');
        i18n.global.locale = lang;
    }

    console.log(`==============================> Stored language is : ${lang}!`);
};