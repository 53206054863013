<template> 
    <div id="scroll-container" class="scroll-container">
        <button @click="scrollTop()" class="btn btn-primary"><i class="fa fa-arrow-up"></i></button>
    </div>
</template>

<script>
export default {
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            let scrollTopButton = document.getElementById('scroll-container');
            if($(window).scrollTop() > 300) {
                scrollTopButton.style.display = 'block';
            } else {
                scrollTopButton.style.display = 'none';
            }
        },
        scrollTop() {
            window.scroll({
                top: 0, 
                left: 0, 
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style scoped>
/* .scroll-container {
  position: absolute;
  top: 88vh;
  right: 10px;
  height: 100%;
} */

.scroll-container {
  position: fixed;
  right: 10px;
  /* height: 100%; */
  /* top: 88vh; */
  bottom: 0;
  display: none;

  bottom: 58px;
}

/* .scroll-container:before {
  content: '';
  display: block;
  height: 100vh;
  pointer-events: none;
}

.scroll-container a {
  position: sticky;
  top: 88vh;
  cursor: pointer;
  font-size: 20px;
} */
</style>