<template>
  <div id="scroll-container" class="scroll-container">
    <button @click="scrollBottom()" class="btn btn-primary"><i class="fa fa-arrow-down"></i></button>
  </div>
</template>

<script>
export default {
  props: {
    target : String
  },
  created() {
    let scrollTopButton = document.getElementById('scroll-container');
    if(scrollTopButton) {
      scrollTopButton.style.display = 'block';
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      let scrollTopButton = document.getElementById('scroll-container');
      if ($(window).scrollTop() < 200) {
        scrollTopButton.style.display = 'block';
      } else {
        scrollTopButton.style.display = 'none';
      }
    },
    scrollBottom() {
      // window.scroll({
      //   bottom: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // });
      console.log('clicked', this.target);
      window.target = this.target;
      window.parent = this.$parent;
      window.t = this;
      if(this.target) {
        this.$parent.$refs[this.target].scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }
}
</script>

<style scoped>
/* .scroll-container {
  position: absolute;
  top: 88vh;
  right: 10px;
  height: 100%;
} */

.scroll-container {
  position: fixed;
  right: 10px;
  /* height: 100%; */
  /* top: 88vh; */
  bottom: 58px;
}

/* .scroll-container:before {
  content: '';
  display: block;
  height: 100vh;
  pointer-events: none;
}

.scroll-container a {
  position: sticky;
  top: 88vh;
  cursor: pointer;
  font-size: 20px;
} */
</style>