import Api from './Api'

export default {
    getUserOffers(status, page) {
        return Api().get(`/getUserOffers/${status}/${page}`)
    },
    getExpertOffers(status, page) {
        return Api().get(`/getExpertOffers/${status}/${page}`)
    },
    getExpertOffer(offerId) {
        return Api().get(`/getExpertOffer/${offerId}`)
    },
    getExpertOfferNews(details) {
        return Api().post('/getExpertOfferNews', details)
    },
    submitOffer(data) {
        return Api().post('/submitOffer', data)
    },
    downloadOffer(fileName) {
        return Api().post('/downloadOffer', fileName, { responseType: 'blob' })
    },
    getOffersByTaskId(id) {
        return Api().get(`/getOffersByTaskId/${id}`)
    }
}
