<template>
  <!--    <div class="d-inline">-->
  <!--        <div v-for="lang in this.availableLocales" :value="lang" class= "d-inline-block"  >-->
  <!--            <a @click="changeLanguage(lang)">-->
  <!--                <div :class="`fflag fflag-${lang} ff-md m-2`" ></div>-->
  <!--            </a>-->

  <!--        </div>-->
  <!--    </div>-->
    <div class="aselect" :data-value="this.language" :data-list="this.availableLocales" ref="selectContainer">
        <div role="button" class="selector" @click="toggle()">
            <div class="label">
                <div :class="`fflag fflag-${this.language} ff-md m-2 selected-language`"></div>
                <span>{{ this.$t(`${this.language}`) }}</span>
            </div>
            <div class="arrow" :class="{ expanded : visible }"></div>
            <div :class="{ hidden : !visible, visible }">
                <ul>
                    <li :class="{ current : item === this.language }" v-for="item in this.availableLocales"
                        @click="select(item)">
                        <div :class="`fflag fflag-${item} ff-md m-2`"></div>
                        {{ this.$t(`${item}`) }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import {locales, defaultLocale} from '../locales/index.js'
import i18n from '../locales/index.js';
import SocketIO from "@/services/SocketIO";
import {Preferences} from '@capacitor/preferences';

//console.log(locales);
export default {
    data() {
        return {
            availableLocales: locales,
            language: this.getLanguage(),
            visible: false
        }
    },
    created() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleClickOutside(event) {
            const selectContainer = this.$refs.selectContainer;

            // If the target element is outside the selectContainer, then close it
            if (selectContainer && !selectContainer.contains(event.target)) {
                this.visible = false;
            }
        },
        toggle() {
            this.visible = !this.visible;
        },
        select(option) {
            this.changeLanguage(option);
        },
        getLanguage() {
            let lang = localStorage.getItem('language');
            if (lang && locales.includes(lang)) {
                return lang;
            } else {
                return defaultLocale
            }
        },
        changeLanguage(value) {
            this.$i18n.locale = value;
            window.localStorage.setItem('language', value);
            Preferences.set({
                key: 'lang',
                value: value,
            });
            this.language = value;
            this.$formkit.setLocale(value);
            this.$store.dispatch("setLocale", this.$i18n.locale);
            if (typeof SocketIO.getSocketInstance() !== 'undefined') {
                SocketIO.getSocketInstance().emit('set-locale', {locale: value});
            } else {
                console.error('Could not emit language change to server from language picker component. Socket Instance was undefined')
            }
            setTimeout(() => {
                this.visible = false;
            }, 100)
        }
    }
}
</script>
<style scoped>

.aselect {
    width: 150px;
    margin: 20px auto;
}

.aselect .selector {
    border-radius: 3px;
    border: 1px solid gainsboro;
    background: #F8F8F8;
    position: relative;
    z-index: 1;
}

.arrow {
    position: absolute;
    right: 10px;
    top: 40%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid #888;
    transform: rotateZ(0deg) translateY(0px);
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(.59, 1.39, .37, 1.01);
}

.expanded {
    transform: rotateZ(180deg) translateY(2px);
}

.label {
    display: block;
    padding: 2px;
    font-size: 16px;
    color: #888;
}

ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    border: 1px solid gainsboro;
    position: absolute;
    z-index: 1;
    background: #fff;
    display: block !important;
}

li {
    padding: 12px;
    color: #666;
}

li:hover {
    color: white;
    background: var(--theme-color);
}

.current {
    background: #eaeaea;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.selected-language {
    border: 0;
    border-radius: 64%;
    width: 20px !important;
    height: 20px !important;
}

.center-lang-picker .selector ul {
    position: absolute !important;
    margin-top: -150px !important;
}

@media only screen and (max-width: 991.98px) {
    .aselect {
        width: 150px;
        margin: 0px auto 0px 15px
    }

    .aselect .selector {
        border: none;
        background: transparent;
    }

    .label {
        color: white;
    }

    .arrow {
        border-top: 10px solid white;
    }
}


.fflag {
    background-image: url('~@/assets/img/flagSprite42.png');
    background-repeat: no-repeat;
    background-size: 100% 49494%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    box-sizing: content-box;
}

.fflag-CH {
    box-shadow: none !important
}

.fflag-AX {
    background-position: center 0.2287%
}

.fflag-AL {
    background-position: center 0.4524%
}

.fflag-AD {
    background-position: center 0.6721%
}

.fflag-AM {
    background-position: center 0.8958%
}

.fflag-AT {
    background-position: center 1.1162%
}

.fflag-BY {
    background-position: left 1.3379%
}

.fflag-BE {
    background-position: center 1.5589%
}

.fflag-BA {
    background-position: center 1.7805%
}

.fflag-BG {
    background-position: center 2.0047%
}

.fflag-HR {
    background-position: center 2.2247%
}

.fflag-CY {
    background-position: center 2.4467%
}

.fflag-CZ {
    background-position: left 2.6674%
}

.fflag-DK {
    background-position: center 2.8931%
}

.fflag-EE {
    background-position: center 3.1125%
}

.fflag-FO {
    background-position: center 3.3325%
}

.fflag-FI {
    background-position: center 3.5542%
}

.fflag-FR {
    background-position: center 3.7759%
}

.fflag-DE {
    background-position: center 4.0015%
}

.fflag-GI {
    background-position: center 4.2229%
}

.fflag-el {
    background-position: left 4.441%
}

.fflag-GG {
    background-position: center 4.66663%
}

.fflag-HU {
    background-position: center 4.8844%
}

.fflag-IS {
    background-position: center 5.1061%
}

.fflag-IE {
    background-position: center 5.3298%
}

.fflag-IM {
    background-position: center 5.5495%
}

.fflag-IT {
    background-position: center 5.7712%
}

.fflag-JE {
    background-position: center 5.994%
}

.fflag-XK {
    background-position: center 6.2156%
}

.fflag-LV {
    background-position: center 6.4363%
}

.fflag-LI {
    background-position: left 6.658%
}

.fflag-LT {
    background-position: center 6.8805%
}

.fflag-LU {
    background-position: center 7.1038%
}

.fflag-MT {
    background-position: left 7.3231%
}

.fflag-MD {
    background-position: center 7.5448%
}

.fflag-MC {
    background-position: center 7.7661%
}

.fflag-ME {
    background-position: center 7.98937%
}

.fflag-NL {
    background-position: center 8.2099%
}

.fflag-MK {
    background-position: center 8.4316%
}

.fflag-NO {
    background-position: center 8.6533%
}

.fflag-PL {
    background-position: center 8.875%
}

.fflag-PT {
    background-position: center 9.0967%
}

.fflag-RO {
    background-position: center 9.32237%
}

.fflag-RU {
    background-position: center 9.5426%
}

.fflag-SM {
    background-position: center 9.7628%
}

.fflag-RS {
    background-position: center 9.9845%
}

.fflag-SK {
    background-position: center 10.2052%
}

.fflag-SI {
    background-position: center 10.4269%
}

.fflag-ES {
    background-position: left 10.6486%
}

.fflag-SE {
    background-position: center 10.8703%
}

.fflag-CH {
    background-position: center 11.0945%
}

.fflag-TR {
    background-position: center 11.3135%
}

.fflag-UA {
    background-position: center 11.5354%
}

.fflag-en {
    background-position: center 11.7593%
}

.fflag-VA {
    background-position: right 11.9799%
}

.fflag-BE.ff-app,
.fflag-FR.ff-app,
.fflag-IE.ff-app,
.fflag-IT.ff-app,
.fflag-RO.ff-app {
    background-size: 100% 50000%
}

.fflag.ff-sm {
    width: 18px;
    height: 11px
}

.fflag.ff-md {
    width: 27px;
    height: 17px
}

.fflag.ff-lg {
    width: 42px;
    height: 27px
}

.fflag.ff-xl {
    width: 60px;
    height: 37px
}

/* ff-app = app icon look */
.ff-app {
    background-size: 155%;
    background-clip: content-box;
    border-radius: 21%;
}

.ff-app:before {
    content: '';
    display: inline-block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(circle at center -166%, rgba(255, 255, 255, .0) 0%, rgba(255, 255, 255, .0) 66%, rgba(255, 255, 255, .5) 80%, rgba(255, 255, 255, 0) 80.5%, rgba(0, 0, 0, .02) 81%, rgba(0, 0, 0, .2) 100%);
}

.ff-app.ff-sm:before, .ff-app.ff-sm {
    width: 12px;
    height: 12px
}

.ff-app.ff-md:before, .ff-app.ff-md {
    width: 18px;
    height: 18px
}

.ff-app.ff-lg:before, .ff-app.ff-lg {
    width: 24px;
    height: 24px
}

.ff-app.ff-xl:before, .ff-app.ff-xl {
    width: 32px;
    height: 32px
}
</style>