<template>
  <!-- <div> -->
  <body class="body-two" ref=bodyTwo>
    <div id="page-container">
      <div id="content-wrap">
        <div :class="`main-wrapper isMobile-${$isMobile()}`">
          <!-- Header -->
          <header :class="`header header-two ${$route.name}-header`">
            <nav class="navbar navbar-expand-lg header-nav">
              <div class="container">
                <div class="navbar-header">
                  <div class="container mobile-hidden">
                    <div class="row text-center">
                      <span class="mobile-controls-ls"
                        >{{}}
                        <a v-if="($store.state.app.pagePrevious == null || $store.state.app.pagePrevious === '/mobile-login') && !excludedMenuPathNames.includes($route.name)" id="mobile_btn" class="mobile-controls-container" href="javascript:void(0);">
                          <span class="bar-icon mobile-controls">
                            <span></span>
                            <span></span>
                            <span></span>
                          </span>
                        </a>
                        <!-- MOBILE MENU: PREVIOUS BUTTON LOGIC -->
                        <i v-if="$store.state.app.pagePrevious !== null && $store.state.app.pagePrevious !== '/mobile-login'" @click="$store.state.app.pagePrevious == $route.path ? $router.push({ path: 'main' }) : this.$router.go(-1)" class="fa fa-arrow-left mobile-back-btn"></i>
                        <span v-if="$store.state.app.pageTitle"
                          ><b class="pageTitle-mobile">{{ this.$t(`pages.${$store.state.app.pageTitle}`) }}</b></span
                        >
                      </span>          
                    </div>
                  </div>

                  <router-link v-if="!$isMobile()" to="/">
                    <span class="navbar-brand logo">
                      <img src="@/assets/img/final-logo.png" style="margin-bottom: 12px" class="img-fluid" alt="Logo" />
                    </span>
                  </router-link>
                  <!-- <router-link to="/">
                <span class="navbar-brand logo-small">
                  <img src="@/assets/img/logo-icon-1.png" class="img-fluid" alt="Logo" />
                </span>
              </router-link> -->
                </div>
                <div class="main-menu-wrapper">
                  <div class="menu-header">
                    <router-link to="/">
                      <span class="menu-logo">
                        <img src="@/assets/img/final-logo.png" class="img-fluid" alt="Logo" />
                      </span>
                    </router-link>
                    <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
                  </div>
                  <ul :class="`main-nav ${$isMobile() ? 'main-nav-mobile' : ''}`">
                    <li v-if="$store.state.user.id == null || ($store.state.user.roles.includes('ROLE_USER') && !platform.packaged)" :class="isActiveItem('main')">
                      <router-link class="home-item" id="menu_close" to="/main"><i v-if="$isMobile()" class="fa fa-home"></i> {{ this.$t("menu.home") }}</router-link>
                    </li>
                    <li v-if="$store.state.user.id == null || $store.state.user.roles.includes('ROLE_USER')" :class="isActiveItem('all-services')">
                      <router-link class="services-item" id="menu_close" to="/all-services"><i v-if="$isMobile()" class="fa fa-wrench"></i> {{ this.$t("menu.services") }}</router-link>
                    </li>
                    <li v-if="!$isMobile() && $store.state.user.id !== null" :class="isActiveItem($store.state.user.roles.includes('ROLE_EXPERT') ? 'expert-dashboard' : 'user-dashboard')">
                      <router-link class="tasks-item" id="menu_close" :to="$store.state.user.roles.includes('ROLE_EXPERT') ? '/expert-dashboard' : '/user-dashboard'">{{ this.$t("menu.userTasks") }}</router-link>
                    </li>
                    <li v-if="$store.state.user.id == null" :class="isActiveItem('register-professional')">
                      <router-link class="register-professional-item" id="menu_close" to="/register-professional"><i v-if="$isMobile()" class="fas fa-hard-hat"></i> {{ this.$t("menu.joinAsProfessional") }}</router-link>
                    </li>
                    <li v-if="$isMobile()" :class="isActiveItem('page')">
                      <router-link class="terms-of-service-item" id="menu_close" :to="{ name: 'page', params: { slug: 'terms-of-service' } }"><i class="fas fa-file-signature"></i> {{ this.$t("menu.termsOfService") }}</router-link>
                    </li>
                    <li v-if="$store.state.user.id !== null && $store.state.user.roles.includes('ROLE_EXPERT')" :class="isActiveItem('expert-news')">
                      <router-link class="expert-news-item" id="menu_close" to="/expert-news"><i v-if="$isMobile()" class="fas fa-search"></i>{{ this.$t("menu.offerPosts") }}</router-link>
                    </li>
                    <li v-if="!$isMobile()" class="language-picker-container"><LanguagePicker></LanguagePicker></li>
                  </ul>

                  <div v-if="$isMobile()" class="bottom-box ">
                    <div style="width: 78%;" class="language-picker-container"><LanguagePicker></LanguagePicker></div>
                    <div class="spacer"></div>
<!--                    <div>-->
<!--                      <a class="bottom-link" target="_blank" href="">Give Feedback</a>-->
<!--                      <span class="bottom-link">|</span>-->
<!--                      <a id="mobile_desktop_link" class="bottom-link" href="" target="_self">Desktop Site</a>-->
<!--                    </div>-->
                    <div style="padding-bottom: 15px">
                      <a class="bottom-link" href="/page/about-us">{{$t('pages.About us')}}</a>
                      <span class="bottom-link">|</span>
                      <a class="bottom-link" href="/page/privacy-policy">{{$t('menu.privacyPolicy')}}</a>
                      <span class="bottom-link">|</span>
                      <a class="bottom-link" href="/page/terms-of-service">{{$t('menu.termsOfService')}}</a>
                    </div>
                    <ul class="d-flex" id="mobile-social-media-icons">
                      <li>
                        <a href="https://instagram.com/instaservicecy" data-social-type="instagram" target="_blank"><i class="fab fa-instagram"></i></a>
                      </li>
                      <li>
                        <a href="https://facebook.com/InstaServiceCy" data-social-type="facebook" target="_blank"><i class="fab fa-facebook"></i></a>
                      </li>
                    </ul>
                    <div class="spacer"></div>
                    <div class="spacer"></div>
                    <div class="spacer"></div>
                    <div class="bottom-link">© Copyright instaservice.com.cy {{(new Date()).getFullYear()}}<br />All Rights Reserved</div>
                    <div class="bottom-spacer"></div>
                  </div>

                </div>
              </div>
              <ul class="nav header-navbar-rht">
                <!-- <li v-if="$isMobile()" class="nav-item">
                  <router-link to="/">
                      <span class="menu-logo">
                        <img src="@/assets/img/final-logoICON.png" class="img-fluid" alt="Logo" />
                      </span>
                    </router-link>
                  
                </li> -->
                <li v-if="($route.name == 'main' && $store.state.user.id == null) || (!$isMobile() && $store.state.user.id == null)" class="nav-item">
                  <a class="nav-link btn-outline" @click="openLoginPopup()">{{ this.$t("login.login") }} / {{ this.$t("login.signup") }}</a>
                </li>
              </ul>
              <LoggedNavbar />
            </nav>
          </header>
          <!-- <div class="content">
        <div class="container"> -->
          <!-- Bonus Tasks (******** TODO : UNCOMMENT LATER **********)-->
          <!-- <div v-if="bonusTasksPaths.includes($route.path) && $store.state.user !== null && $store.state.user.roles.includes('ROLE_EXPERT')" class="mt-2">
            <div v-if="$store.state.user.hasActiveSubscription == 0" class="text-center">
              <div v-if="$store.state.user.remainingBonusTasks > 0" class="alert alert-info">{{ this.$t("subscriptionAlerts.bonusTasksRemaining") }} : {{ $store.state.user.remainingBonusTasks }}</div>
              <div v-else class="alert alert-warning">
                {{ this.$t("subscriptionAlerts.noBonusTasks") }}
              </div>
            </div>
          </div> -->
          <!-- End bonus tasks -->
          <router-view />
          <!-- </div>
      </div> -->
          <!--  -->
          <!-- <LoginComponent /> -->
          <!-- { pathName: 'user-favourites', iconClass: 'far fa-heart' } -->
          <!-- { pathName: 'all-services', iconClass: 'fa fa-plus', hasAction: true }, -->
          <div class="mobile-footer-container" v-if="$store.state.user !== null">
            <!-- USER MOBILE FOOTER MENU -->
            <MobileFooter
              v-if="$store.state.user.roles.includes('ROLE_USER')"
              :menuItems="[
                { pathName: 'user-dashboard', iconClass: 'fas fa-bolt', counter: $store.state.counters.unreadTaskRequestsCount, header: $t('mobileMenu.dashboard') },
                { pathName: 'user-offers', iconClass: 'fa fa-gavel', counter: $store.state.counters.unreadOffersCount, header: $t('mobileMenu.offers') },
                //{ pathName: '', iconClass: 'floating-action-button' },
                { pathName: 'mobile-categories', iconClass: 'fas fa-plus-circle main-button', header: $t('mobileMenu.addJob'), class: 'main-button' },
                { pathName: 'user-chat', iconClass: 'fa fa-comments', counter: $store.state.counters.unreadChatroomsCount, header: $t('mobileMenu.chat') },
                { pathName: 'user-notifications', iconClass: 'fas fa-bell', counter: $store.state.counters.notificationsCount, header: $t('mobileMenu.notifications') },
              ]"
            />
            <!-- EXPERT MOBILE FOOTER MENU -->
            <MobileFooter
              v-if="$store.state.user.roles.includes('ROLE_EXPERT')"
              :menuItems="[
                { pathName: 'expert-dashboard', iconClass: 'fas fa-bolt', counter: $store.state.counters.unreadTaskRequestsCount, header: $t('mobileMenu.dashboard') },
                { pathName: 'expert-offers', iconClass: 'fa fa-gavel', counter: $store.state.counters.unreadOffersCount, header: $t('mobileMenu.offers') },
                // { pathName: 'user-tasks', iconClass: 'far fa-calendar-check' },
                { pathName: '', iconClass: 'floating-action-button' },
                { pathName: 'user-chat', iconClass: 'fa fa-comments', counter: $store.state.counters.unreadChatroomsCount, header: $t('mobileMenu.chat') },
                { pathName: 'user-notifications', iconClass: 'fas fa-bell', counter: $store.state.counters.notificationsCount, header: $t('mobileMenu.notifications') },
              ]"
            />
          </div>
          <!-- Footer -->

          <!-- /Footer -->
        </div>
      </div>
    </div>
    <footer v-if="$route.name == 'main' || !$isMobile()" class="footer footer-two">
        <!-- Footer Top -->
        <div class="footer-top aos">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">{{$t("Quick Links")}}</h2>
                            <ul>
                                <li>
                                    <a href="/page/about-us">{{$t('pages.About us')}}</a>
                                </li>
                                <li>
                                    <a href="https://kgk7f5nxywi.typeform.com/to/hlcGHs60" target="_blank">{{$t('pages.Contact us')}}</a>
<!--                                    <router-link :to="{ name: 'contact-us' }"><a>Contact Us</a></router-link>-->
                                </li>
                                <li><a href="mailto:info@instaservice.com.cy">Email: info@instaservice.com.cy</a></li>
                                <li>
                                    <router-link :to="{ name: 'faq' }"><a>{{ $t('menu.faq') }}</a></router-link>
                                </li>
                                <!-- <li>
                            <a href="#">Help</a>
                          </li> -->
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">{{ this.$t('labels.categories') }}</h2>
                            <ul>
                                <li v-for="category in categories" :key="category.id" >
                                    <router-link :to="{ name: 'services', params: { categoryName: helpers.generateSeoFriendlyUrl(category.name), categoryId: category.id }}">
                                        <a>{{ this.translate(this.$i18n.locale, category.translations, category.name) }}</a>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-3 col-md-6 d-none">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-contact">
                            <h2 class="footer-title">Address</h2>
                            <div class="footer-contact-info">
                                <div class="footer-address">
                                    <span><i class="far fa-building"></i></span>
                                    <p>367 Hillcrest Lane, Irvine, California, United States</p>
                                </div>
                                <p><i class="fas fa-headphones"></i> 321 546 8764</p>
                                <p class="mb-0"><i class="fas fa-envelope"></i> instaservice@example.com</p>
                            </div>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">{{ this.$t('labels.followUs') }}</h2>
                            <div class="social-icon">
                                <ul>
                                    <li>
                                        <a href="https://facebook.com/InstaServiceCy" target="_blank"><i class="fab fa-facebook"></i> </a>
                                    </li>
                                    <!-- <li>
                                      <a href="#" target="_blank"><i class="fab fa-linkedin"></i> </a>
                                    </li> -->
                                    <li>
                                        <a href="https://instagram.com/instaservicecy" target="_blank"><i class="fab fa-instagram"></i></a>
                                    </li>
                                    <!-- <li>
                                      <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                                    </li> -->
                                </ul>
                            </div>
                            <!-- <div class="subscribe-form">
                                <input type="email" class="form-control" placeholder="Enter your email" />
                                <a href="search.html" class="btn footer-btn d-flex align-items-center justify-content-center">
                                    <i class="fas fa-paper-plane"></i>
                                </a>
                            </div> -->
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <!-- Copyright -->
                <div class="copyright">
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div class="copyright-text">
                                <p class="mb-0">&copy; {{ new Date().getFullYear() }} <a href="/">Instaservice</a>. All rights reserved.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <!-- Copyright Menu -->
                            <div class="copyright-menu">
                                <ul class="policy-menu">
                                    <li>
                                        <router-link :to="{ name: 'page', params: { slug: 'terms-of-service' } }">
                                            <a>{{ this.$t('menu.termsOfService') }}</a>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'page', params: { slug: 'privacy-policy' } }">
                                            <a>{{ this.$t('menu.privacyPolicy') }}</a>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                            <!-- /Copyright Menu -->
                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
        <!-- /Footer Bottom -->
    </footer>
  </body>
  <div v-if="$route.path !== '/chat-message'">
    <ScrollTop />
  </div>
  <!-- </div> -->
  <div v-if="$store.state.user">
    <SubscriptionAlert />
  </div>
</template>
<script>
// import { GDialog } from 'gitart-vue-dialog'
import helpers from "@/helpers/helpers"
import {translate} from "@/helpers/Translate";
import LoggedNavbar from "@/layouts/LoggedNavbar";
import MobileFooter from "@/layouts/MobileFooter";
import ScrollTop from "@/components/ScrollTop";
import EventBus from "@/helpers/EventBus";
import FloatingActionButton from "@/components/FloatingActionButton";
import LanguagePicker from "@/components/LanguagePicker";
import SubscriptionAlert from "@/components/auth/SubscriptionAlert";
import CategoryService from "@/services/CategoryService"
// import LoginComponent from "@/components/auth/LoginComponent";
const bonusTasksPaths = ["/expert-offers", "/expert-dashboard", "/expert-news"];
const excludedMenuPathNames = ["add-task"];
import platform from "platform-detect";

export default {
  components: {
    // LoginComponent,
    LoggedNavbar,
    MobileFooter,
    ScrollTop,
    FloatingActionButton,
    LanguagePicker,
    SubscriptionAlert,
  },
  data() {
    return {
      platform,
      openLoginDialog: false,
      bonusTasksPaths,
      excludedMenuPathNames,
      categories: null,
      helpers,
      translate
    };
  },
  async mounted() {
     await CategoryService.getCategories().then((resp) => {
          this.categories = resp.data;
      });
  },
  methods: {
    isActiveItem(itemUrl) {
      if (this.$route.name == itemUrl) {
        return "active";
      }
    },
    openLoginPopup() {
      EventBus.$emit("OPEN_LOGIN_POPUP");
    },
    displaySubMenu(event) {
      event.stopPropagation();
      let element = event.target;

      if (element.nodeName == "A") {
        if (element.parentNode.querySelector(".submenu").style.display === "block") {
          element.parentNode.querySelector(".submenu").style.display = "";
        } else {
          element.parentNode.querySelector(".submenu").style.display = "block";
        }

        var subMenus = document.querySelectorAll(".submenu");
        subMenus.forEach(function (item) {
          if (item !== element.parentNode.querySelector(".submenu")) {
            item.style.display = "";
          }
        });
      }
    },
  },
};
</script>
<style scoped>
#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  /*padding-bottom: 25rem;*/
}

/* Mobile Menu Sidebar */
.main-nav-mobile li {
  display: block;
  width: 100%;
  font-size: 13px;
  text-decoration: none;
  border-top: 1px solid #1f8b57;
  border-bottom: 1px solid #145838;
  line-height: 35px;
  height: 47px;
  overflow: hidden !important;
  background: #1f8b57;
}
.main-nav-mobile li a {
  line-height: 0.9;
}

.main-nav-mobile li a i {
  padding-right: 5px;
  float: unset;
  margin-top: unset;
}
.bottom-box {
    text-align: center;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: var(--theme-color);
    margin: 0;
    padding: 30px 0;
    position: fixed;
    bottom: 0;
}
.bottom-link {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    margin: 0 3px 10px;
}
#mobile-social-media-icons a {
    display: inline-block;
    line-height: 20px;
    height: auto;
    color: white;
    font-size: 17px;
}
#mobile-social-media-icons li {
  padding-right: 10px;
}
#mobile-social-media-icons {
  margin-bottom: 7%;
}
/* /Mobile Menu Sidebar */
</style>
