<template>
  <div class="row">
    <h3 v-if="this.showHeading" class="mb-2">{{ this.$t('login.loginToSubmitTask') }}</h3>
    <div v-show="!phoneOTPDialog.otpStep" class="step-1">
      <div class="d-flex" style="margin-bottom: 2px;">
        <div data-v-95e27e3f="">{{ this.$t('login.codeSentVia') }}</div>
        <svg data-v-5dcd6e7d="" data-v-95e27e3f="" type="image/svg+xml" viewBox="956 528.1576232910156 24 24" width="16"
             height="16" fill="none" class="icon" style="min-width: 16px; min-height: 16px; margin: 0px 2px 0px 4px;">
          <g id="icons / 24 px / review">
            <g id="Group 26333">
              <path id="Vector_54"
                    d="M970 546.923C973.771 546.923 975.657 546.923 976.828 545.751C978 544.58 978 542.694 978 538.923C978 535.152 978 533.266 976.828 532.095C975.657 530.923 973.771 530.923 970 530.923H966C962.229 530.923 960.343 530.923 959.172 532.095C958 533.266 958 535.152 958 538.923C958 542.694 958 544.58 959.172 545.751C959.825 546.405 960.7 546.694 962 546.821"
                    stroke="#040C15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path id="Vector_55"
                    d="M970 546.923C968.764 546.923 967.402 547.423 966.159 548.068C964.161 549.105 963.162 549.624 962.67 549.293C962.178 548.963 962.271 547.938 962.458 545.889L962.5 545.423"
                    stroke="#040C15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
            <line id="Line 8" x1="962.75" y1="536.25" x2="973.25" y2="536.25" stroke="#040C15" stroke-width="1.5"
                  stroke-linecap="round"></line>
            <line id="Line 9" x1="962.75" y1="540.25" x2="970.25" y2="540.25" stroke="#040C15" stroke-width="1.5"
                  stroke-linecap="round"></line>
          </g>
        </svg>
        <div data-v-95e27e3f="" style="font-weight: 600;">SMS</div>
      </div>
      <p class="otpHelp">{{ this.$t("login.phoneOtpHelp") }}</p>
      <div class="col-md-12 mb-2">
        <!--                  <FormKit type="text" v-model="expertRegistration.phone" validation-visibility="dirty" :label="this.$t('formFields.phone')" :placeholder="this.$t('formFields.phone')" validation="required" />-->
        <FormKit
            ref="phoneInput"
            type="telInput"
            v-model="phoneOTPDialog.phone"
            :only-countries="['CY', 'GR']"
            :custom-countries-list="{
                      CY: $t('Cyprus'),
                      GR: $t('Greece'),
                    }"
            :translations="{
                      countrySelector: {
                        placeholder: $t('Country code'),
                      },
                      phoneInput: {
                        placeholder: $t('Phone number'),
                        example: $t('Example:'),
                      },
                    }"
            :label="this.$t('formFields.phone')"
            validation-visibility="dirty"
            validation="required|validPhone"
            :outer-class="{
                      'formkit-outer': false,
                    }"
            :inner-class="{
                      'formkit-inner': false,
                    }"
            :help="this.$t('Use mobile phone number to receive an OTP vis SMS')"
        />
        <div ref="phoneError" class="text-danger" id='phone-error' style="display:none">
          {{ this.$t('labels.phoneIsRequired') }}
        </div>
        <button @click="sendOtp()" :class=" ($isMobile()) ? 'btn btn-primary mt-3 w-100' : 'btn btn-primary mt-3'">{{ this.$t("Next") }}</button>
        <div style="font-size:25px;margin-left:25px;display:inline-block" v-show="phoneOTPDialog.spinner" id="spinner">
          <i class="fas fa-spin fa-spinner"></i>
        </div>

      </div>
    </div>
    <div v-show="phoneOTPDialog.otpStep" class="">
      <p>{{ this.$t('login.phoneVerifyText', {phone: this.phoneOTPDialog.phone}) }}</p>
      <p class="otpHelp"  v-html="this.$t('labels.otpMessage')"></p>
      <FormKit :actions="false"
               @submit="verifyOtp"
               enctype="multipart/form-data"
               id="otpVerify"
               submit-label="Create Task"
               type="form"
      >
        <FormKit v-model="phoneOTPDialog.otp"
                 type="number"
                 validation-visibility="live"
                 :label="this.$t('login.otpLabel')"
                 placeholder="* * * * * *"
                 validation="required|number|length:6,6|between:100000,999999"
        ></FormKit>
        <div v-if="phoneOTPDialog.userDetailsRequired" class="row">
          <p>{{ this.$t('labels.pleaseProvideYourDetails') }}</p>
          <div class="col-md-6">
            <FormKit type="text" v-model="phoneOTPDialog.user.firstName" validation-visibility="live"
                     :label="this.$t('formFields.firstName')" :placeholder="this.$t('formFields.firstName')"
                     validation="required"/>
          </div>
          <div class="col-md-6">
            <FormKit type="text" v-model="phoneOTPDialog.user.lastName" validation-visibility="live"
                     :label="this.$t('formFields.lastName')" :placeholder="this.$t('formFields.lastName')"
                     validation="required"/>
          </div>
        </div>
        <div ref="sticky-buttons" class="sticky-buttons">
          <button @click="phoneOTPDialog.otpStep = false;" class="btn btn-secondary mb-2" style="margin-right:10px;">
            {{ this.$t("login.changePhone") }}
          </button>
          <button ref="submitBtn" type="submit" class="btn btn-primary mb-2">{{ this.$t("labels.submit") }}</button>
        </div>
      </FormKit>
      <ScrollBottom v-if="typeof showScroll !== 'undefined' && showScroll == true" :target="'sticky-buttons'"></ScrollBottom>
    </div>
  </div>
</template>
<script>
import AuthenticationService from "@/services/AuthenticationService";
import Notify from "@/helpers/Notify";
import ScrollBottom from "@/components/ScrollBottom.vue";
import helpers from "@/helpers/helpers";

export default {
  props: {
    showHeading: Boolean,
    showScroll: Boolean
  },
  emits: ['otpVerifyCall'],
  components: {
    ScrollBottom
  },
  data() {
    console.log('PROPS', this.showHeading);
    return {
      phoneOTPDialog: {
        show: false,
        otpStep: false,
        otp: null,
        phone: null,
        userDetailsRequired: false,
        user: {
          firstName: null,
          lastName: null
        },
        spinner: false,
        taskValues: null
      },
    }
  },
  methods: {
    async sendOtp() {
      window.phone = this.phoneOTPDialog.phone
      document.getElementById('phone-error').style.display = 'none';
      if (!this.phoneOTPDialog.phone || this.phoneOTPDialog.phone.length < 1) {
        document.getElementById('phone-error').style.display = 'block';
        return;
      }
      if (typeof this.$refs.phoneInput.node.props.isValid !== 'undefined' && !this.$refs.phoneInput.node.props.isValid) {
        return;
      }
      try {
        this.phoneOTPDialog.spinner = true;
        const self = this;
        window.t = this;
        console.log(this.phoneOTPDialog.spinner)
        await AuthenticationService.smsOtpSend(this.phoneOTPDialog.phone).then(resp => {
          window.resp = resp;
          self.phoneOTPDialog.spinner = false;
          const data = resp.data;
          if (!resp.data) {
            console.error('No data was received');
            return;
          }
          this.phoneOTPDialog.otp = null;
          if (data.otpCreated) {
            Notify.displaySuccess(data.message);
            this.phoneOTPDialog.otpStep = true;
            this.phoneOTPDialog.userDetailsRequired = !!data.userDetailsRequired;
          }
        });
      } catch (error) {
        window.err = error;
        Notify.displayError(error.response.data.message);
        this.phoneOTPDialog.spinner = false;
      }
    },
    async verifyOtp() {
      const element = this.$refs.submitBtn;
      const bottom = element.offsetBottom;
      window.scrollTo({
        top: bottom,
        behavior: 'smooth'
      });
      //sent request to server to validate otp
      console.log('VERIFY');
      const data = {
        otp: this.phoneOTPDialog.otp,
        data: this.getUtms()
      }
      if (this.phoneOTPDialog.userDetailsRequired) {
        data.data = {... data.data, ...this.phoneOTPDialog.user};
      }
      window.d = data;
      try {
        const self = this;
        await AuthenticationService.loginViaPhone(this.phoneOTPDialog.phone, data).then(resp => {
          this.$store.dispatch("setUser", resp.data);
          if (resp.data) {
            const utms = self.getUtms();
            if(this.phoneOTPDialog.userDetailsRequired) {
              window.dataLayer.push({
                'event': 'UserRegistration',
                'utm_source': utms.utm_source,
                'utm_content': utms.utm_content,
                'utm_campaign': utms.utm_campaign,
                'utm_term': utms.utm_term,
                'utm_medium': utms.utm_medium,
                'gclid': utms.gclid,
                'visitorType': 'user'
              });
            } else {
              console.log('SUbmit-Task-Form')
              window.dataLayer.push({
                'event': 'Submit-Task-Form',
                'utm_source': utms.utm_source,
                'utm_content': utms.utm_content,
                'utm_campaign': utms.utm_campaign,
                'utm_term': utms.utm_term,
                'utm_medium': utms.utm_medium,
                'gclid': utms.gclid,
                'visitorType': 'user'
              });
            }
            setTimeout(() => {
              console.log('emit event: otpVerifyCall')
              self.$emit('otpVerifyCall', {otpLoginData: self.phoneOTPDialog, loginViaPhone: resp.data});
            },2000)

          }
          window.t = this;
          window.resp = resp;
          console.log('resp', resp)
        })

      } catch (error) {
        console.log(error);
        window.erra = error;
        Notify.displayError(error.response.data.message);
      }

    },
    getUtms() {
      return {
        utm_source : helpers.getCookie('utm_source') !== "" ? helpers.getCookie('utm_source') : (this.$route.query.utm_source ? this.$route.query.utm_source : null),
        utm_content : helpers.getCookie('utm_content') !== "" ? helpers.getCookie('utm_content') : (this.$route.query.utm_content ? this.$route.query.utm_content : null),
        utm_campaign : helpers.getCookie('utm_campaign') !== "" ? helpers.getCookie('utm_campaign') : (this.$route.query.utm_campaign ? this.$route.query.utm_campaign : null),
        utm_term : helpers.getCookie('utm_term') !== "" ? helpers.getCookie('utm_term') : (this.$route.query.utm_term ? this.$route.query.utm_term : null),
        utm_medium : helpers.getCookie('utm_medium') !== "" ? helpers.getCookie('utm_medium') : (this.$route.query.utm_medium ? this.$route.query.utm_medium : null),
        gclid : helpers.getCookie('gclid') !== "" ? helpers.getCookie('gclid') : (this.$route.query.gclId ? this.$route.query.gclId : null)
      }



    }
  }
}
</script>
<style scoped>
.otpHelp {
  font-size: 13px !important
}
</style>