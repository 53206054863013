import { store } from '@/store'
import router from '@/router'
import {translate} from "@/helpers/Translate";
import i18n from '@/locales/index';

class Incoming {

    handle(data) {
        switch(data.type) {
            case 'TASK-REQUEST-INCREMENT': 
                this.taskRequestIncrement();
                break;
            case 'EXPERT-ASSIGNMENT-NOTIFICATION':
                this.navigateTo(data);
                break;
        }
    }

    taskRequestIncrement() {
        store.dispatch("setUnreadTaskRequestsCount", store.state.counters.unreadTaskRequestsCount + 1);
    }

    navigateTo(data) {
        let taskTitle = i18n.global.t(data.taskDetails.title);
        router.push({name:'actions-response', query:
          {   success: true,
              title: i18n.global.t(`{task} is assigned to you`,{task:taskTitle}),
              message: i18n.global.t("Contact the customer for details or navigate on the map"),
              buttons: JSON.stringify([
                {text: i18n.global.t("messages.Go to task"), class: 'btn-primary', routerPath:`/task/${data.taskDetails.id}`},
              ]),
              externalLinks: JSON.stringify([
                {text:i18n.global.t("messages.Open in map"), class: 'btn-secondary', url:`http://www.google.com/maps/place/${data.taskDetails.lat}, ${data.taskDetails.lng}`},
              ])
          }
        });
    }

}

const IncomingNotification = new Incoming()
  
export default IncomingNotification