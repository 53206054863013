import AuthenticationService from "@/services/AuthenticationService";
import EventBus from "@/helpers/EventBus"
import {store} from '@/store'
import router from '@/router'
import platform from "platform-detect";

export default {
    generateSeoFriendlyUrl(str) {
        return str.replace(/[^A-Z0-9&ά-ωΑ-ώ]/ig, "-");
    },

    logout(openLoginModal = false) {
        try {
            AuthenticationService.signout().then((resp) => {
                EventBus.$emit("DISCONNECT_SOCKET");
                store.dispatch('setToken', null);
                store.dispatch('setUser', {
                    id: null,
                    // username: user.username,
                    email: null,
                    firstName: null,
                    lastName: null,
                    homeAddress: null,
                    lat: null,
                    lng: null,
                    roles: [],
                    accessToken: null,
                    refreshToken: null,
                    profileImage: null,
                    profileImageMeta: null,
                    phone: null,
                    services: null,
                    activated: null,
                    validated: null,
                    subscription: {
                        stripeCustomerId: null,
                        checkoutPackage: null
                    },
                    totalRating: null,
                    hasActiveSubscription: null,
                    remainingBonusTasks: null
                });
                localStorage.removeItem("sessionID");
                router.push({
                    path: '/'
                }).then(() => {
                    if (openLoginModal && !platform.packaged) {
                        EventBus.$emit("OPEN_LOGIN_POPUP");
                    }
                })
            });
        } catch (error) {
            console.log('error', error)
        }
    },

    handleTokenError(err) {
        if (err.response.data.reason == "TokenExpired") {
            AuthenticationService.refreshAccessToken(store.state.user.refreshToken)
                .then((resp) => {
                    let token = resp.data.accessToken;
                    store.dispatch("setToken", token);
                }, err => {
                    this.logout(true);
                });
        }
    },

    isFavourite(expertId) {
        return (store.state.user.userFavourites.map(e => e.providerId).indexOf(expertId) >= 0 ? true : false);
    },

    readableDate(uDate) {
        const date = new Date(uDate);
        const year = date.getFullYear();
        const month = date.getMonth(); // getMonth returns a zero-based index of the month: 0-11
        const day = date.getDate(); // 0 - 31
        const hours = date.getHours(); // 0 - 23
        const minutes = date.getMinutes(); // 0 - 59
        // const seconds = date.getSeconds(); // 0 - 59

        const addZero = (num) => `${num}`.padStart(2, '0');

        const formatted =
            addZero(day) +
            '/' +
            addZero(month + 1) +
            '/' +
            year +
            ' ' +
            addZero(hours) +
            ':' +
            addZero(minutes);
        // ':' +
        // addZero(seconds);

        return formatted;
    },

    readableDateFormat(date) {
        const createdAt = new Date(date);
        const day = createdAt.getDate();
        const month = createdAt.getMonth() + 1; // Months are zero-indexed, so add 1
        const year = createdAt.getFullYear();

        // Create the formatted date string with zero-padding if needed
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    },

    getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', {month: 'long'});
    },

    chatAction(receiverId, name) {
        store.dispatch("setChatRoom", {receiverId, chatRoomId: null, name: name});
        router.push('/chat-message');
    },

    validateFileUpload(file) {
        const validExtensions = ['.pdf', '.docx', '.doc', '.csv', '.xls', '.xlsx', '.ppt', '.pptx', '.zip', '.rar', '.png', '.jpeg', '.jpg', '.odp', '.ods', '.odt', '.txt'];
        const validTypes = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/pdf',
            'application/vnd.oasis.opendocument.presentation',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.text',
            'application/zip',
            'application/x-7z-compressed',
            'application/vnd.rar',
            'image/png',
            'image/jpeg',
            'application/rtf',
            'text/plain',
            'text/csv'
        ];
        const maxSize = 1048576; // in bytes. 1048576 B = 1 MB

        let extension = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : undefined;
        if (extension !== undefined) {
            if (!validExtensions.includes('.' + extension)) {
                return {
                    success: false,
                    msg: `Invalid file. Allowed types are: ${validExtensions.join(', ')}`,
                    translationKeys: {
                        key: 'messages.InvalidFileType',
                        params: {types: validExtensions.join(', ')}
                    }
                };
            }
        }
        if (!validTypes.includes(file.type)) {
            return {
                success: false,
                msg: `Invalid files. Allowed types are: ${validExtensions.join(', ')}`,
                translationKeys: {
                    key: 'messages.InvalidFileType',
                    params: {types: validExtensions.join(', ')}
                }
            };
        }

        if (file.size > maxSize) {
            return {
                success: false,
                msg: `Invalid file size. Max file size is ${maxSize / (1024 * 1024)} MB`,
                translationKeys: {
                    key: 'messages.InvalidFileSize',
                    params: {maxSize: maxSize / (1024 * 1024)}
                }
            };
        }
        return {
            success: true
        };

    },

    validateImageFile(file) {
        const validExtensions = ['.png', '.jpeg', '.jpg'];
        const validTypes = [
            'image/png',
            'image/jpeg',

        ];
        const maxSize = 5242880; // in bytes. 1048576 B = 1 MB. 5242880  = 5 MB
        const extension = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : undefined;

        if (extension !== undefined) {//`Invalid file. Allowed types are: ${validExtensions.join(', ')}` +
            if (!validExtensions.includes('.' + extension)) {
                return {
                    success: false,
                    msg: `Invalid files. Allowed types are: ${validExtensions.join(', ')}`,
                    translationKeys: {
                        key: 'messages.InvalidFileType',
                        params: {types: validExtensions.join(', ')}
                    }
                };
            }
        }
        if (!validTypes.includes(file.type)) {
            return {
                success: false,
                msg: `Invalid files. Allowed types are: ${validExtensions.join(', ')}`,
                translationKeys: {
                    key: 'messages.InvalidFileType',
                    params: {types: validExtensions.join(', ')}
                }
            };
        }

        if (file.size > maxSize) {
            return {
                success: false,
                msg: `Invalid file size. Max file size is ${maxSize / (1024 * 1024)} MB`,
                translationKeys: {
                    key: 'messages.InvalidFileSize',
                    params: {maxSize: maxSize / (1024 * 1024)}
                }
            };
        }
        return {
            success: true
        };
    },

    isGreek(str) {
        const greekRange = /[\u0370-\u03FF]/; // Greek Unicode range
        return greekRange.test(str);
    },

    stopImpersonate() {
        EventBus.$emit("DISCONNECT_SOCKET");
        store.dispatch('setToken', null);

        store.dispatch('setUser', store.state.currentUser);
        store.dispatch('setCurrentUser', null);

        router.push({path: '/admin'});
    },

    // Accepts format directly from DB: "2023-03-08T17:26:03.000Z"
    isDateToday(givenDateStr) {
        const givenDate = new Date(givenDateStr);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        return givenDate >= currentDate && givenDate < new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    },
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}