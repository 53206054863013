<template>
    <li class="nav-item dropdown logged-item navbar-notifications">
        <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-bell"></i> <span v-if="$store.state.counters.notificationsCount > 0"
                                              class="badge badge-pill bg-yellow pulsate">{{
            $store.state.counters.notificationsCount
            }}</span>
        </a>
        <div class="dropdown-menu notify-blk dropdown-menu-end notifications">
            <div class="topnav-dropdown-header">
                <span class="notification-title">{{ this.$t("notifications.notifications") }}</span>
                <a role="button" @click="markAllAsSeen()" class="clear-noti">{{
                    this.$t("notifications.clearAll")
                    }} </a>
            </div>
            <div class="noti-content">
                <ul v-for="(notification, index) in notificationsList" :key="notification.id" class="notification-list">
                    <li @click="readNotification(notification.id, index, notification.url)"
                        :class="!notification.seen ? 'notification-message unseen-notification' : 'notification-message'">
                        <router-link to="/notifications">
                            <div class="media d-flex">
                <span class="avatar avatar-sm flex-shrink-0">
                  <img v-if="notification.referredUserId" class="avatar-img rounded-circle" alt="User Image"
                       :src="this.apiUrl+`api/users/${notification.referredUserId}/picture/get`"/>
                  <div v-else class="p-1"><i class="far fa-bell"></i></div>
                </span>
                                <div class="media-body flex-grow-1">
                                    <p class="noti-details">
                                        <span class="noti-title" v-if="notification.translationKey">{{
                                            this.translateNotificationMessage(notification.translationKey, notification.translationData)
                                            }}</span>
                                        <span class="noti-title" v-else>{{ notification.message }}</span>
                                    </p>
                                    <p class="noti-time"><span
                                        class="notification-time">{{ (notification.createdAt) ? helpers.readableDate(notification.createdAt) : '' }}</span></p>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="topnav-dropdown-footer">
                <router-link to="/user-notifications">{{ this.$t("notifications.viewAll") }}</router-link>
            </div>
        </div>
    </li>
</template>

<script>
import NotificationService from "@/services/NotificationService";
import SocketIO from "@/services/SocketIO";
import Notify from "@/helpers/Notify";
import Api from "@/services/Api";
import IncomingNotification from "@/helpers/IncomingNotification"
import helpers from "@/helpers/helpers";

export default {
    data() {
        return {
            helpers,
            notificationCounter: 0,
            notificationsList: [],
            apiUrl: Api().defaults.baseURL,
            notificationsListener: this.notificationListener
        };
    },
    mounted() {
        if (SocketIO.getSocketInstance()) {
            SocketIO.getSocketInstance().on("incoming-notification", this.notificationsListener);
        }

        this.getNotifications();
    },
    beforeUnmount() {
        //clear all listeners before unmounting
        if (SocketIO.getSocketInstance()) {
            SocketIO.getSocketInstance().off("incoming-notification", this.notificationsListener);
        }
        clearInterval(this.polling);
    },
    methods: {
        notificationListener(data) {
            console.log('incoming-notification -> ', data)
            this.notificationsList.unshift(data);
            this.notificationCounter += 1;
            this.$store.dispatch("setNotificationsCount", this.notificationCounter);
            if (data.action !== undefined) {
                IncomingNotification.handle(data.action);
            }
        },
        translateNotificationMessage(key, data) {
            if (data && data.task) {
                let translations = JSON.parse(JSON.stringify(data));
                translations.task = this.$t(translations.task);
                if(data.taskId){
                    translations.task+= ` (#${data.taskId})`
                }
                return this.$t(key, translations);
            }
            return this.$t(key, data);
        },
        async getNotifications() {
            await NotificationService.getNotifications().then((resp) => {
                this.notificationsList = resp.data.notifications;
                this.notificationCounter = this.notificationsList.filter((obj) => obj.seen === false).length;
                this.$store.dispatch("setNotificationsCount", this.notificationCounter);
                window.notifications = this.notificationsList;
            });
        },
        async readNotification(id, index, url) {
            if (!this.notificationsList[index].seen) {
                await NotificationService.markAsSeen({id}).then((resp) => {
                    if (resp.status == 200) {
                        this.notificationsList[index].seen = true;
                        this.notificationCounter -= 1;
                        this.$store.dispatch("setNotificationsCount", this.notificationCounter);
                    }
                });
            }
            this.$router.push({
                path: url,
            });
        },
        async markAllAsSeen() {
            try {
                await NotificationService.markAllAsSeen().then((resp) => {
                    this.$store.dispatch("setNotificationsCount", 0);
                    Notify.displaySuccess(resp.data.message);
                });
            } catch (error) {
                Notify.displayError(error.response.data.message);
            }
        },
        // navigateTo(data) {
        //     this.$router.push({name:'actions-response', query:
        //       {   success: true,
        //           title: this.$t(`${data.taskDetails.title} is assigned to you`),
        //           message: this.$t("Contact the customer for details or navigate on the map"),
        //           buttons: JSON.stringify([
        //             {text: this.$t("messages.Go to task"), class: 'btn-primary', routerPath:`/task/${data.taskDetails.id}`},
        //           ]),
        //           externalLinks: JSON.stringify([
        //             {text:this.$t("messages.Open in map"), class: 'btn-secondary', url:`http://www.google.com/maps/place/${data.taskDetails.lat}, ${data.taskDetails.lng}`},
        //           ])
        //       } });
        // }
    },
};
</script>

<style scoped>
.unseen-notification {
    background-color: #e3e2e2;
}
</style>
