import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging/sw";
import { isSupported } from 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyAnjCgY3T_xEKucwPwzNYzcfr0aIqIINPQ",
    authDomain: "instaservices-e0ab4.firebaseapp.com",
    projectId: "instaservices-e0ab4",
    storageBucket: "instaservices-e0ab4.appspot.com",
    messagingSenderId: "1062442616993",
    appId: "1:1062442616993:web:cfd3b9c8a13ab483c688e0",
    measurementId: "G-FLH0LBWJY3"
}

const app = initializeApp(firebaseConfig);
let firebaseMessaging = null;
if(navigator.cookieEnabled &&
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window &&
    'fetch' in window &&
    ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
    PushSubscription.prototype.hasOwnProperty('getKey')) {
    firebaseMessaging = getMessaging(app);
}
// Initialize Firebase Cloud Messaging and get a reference to the service

window.fcm = firebaseMessaging;
export default firebaseMessaging;