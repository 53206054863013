import axios from 'axios'
import { store } from '@/store'
import helpers from "@/helpers/helpers";
const config = require('../../config');

const api = axios.create({
  baseURL: `${config.API_URL}:${config.API_PORT}/`,
  headers: {
    // Authorization: `Bearer ${store.state.token}`,
    // 'x-access-token': store.getters.getUser !== null ? store.getters.getUser.accessToken : null,
    // 'locale':store.getters.getLocale !== null ? store.getters.getLocale : 'en'
  }
})

api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error:
  // if the error is token expiration then force logout the user
  if(error.response && error.response.data && error.response.data.reason && error.response.data.reason === 'TokenExpired') {
    helpers.logout(true);
  }
  return Promise.reject(error);
});
api.interceptors.request.use(function (config) {
  //set the auth headers and local for each request
  config.headers['x-access-token'] = store.getters.getUser !== null ? store.getters.getUser.accessToken : null;
  config.headers['locale'] = store.getters.getLocale !== null ? store.getters.getLocale : 'en';
  return config;
});

export default () => {
  return api;
}