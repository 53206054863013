<template>
  <div class="button-container">
    <input type="checkbox" id="toggle"/>
    <label :class="$store.state.user.roles.includes('ROLE_EXPERT') ? 'button-expert' : 'button'" for="toggle" ref="actionButton" @click="changeVisibility"></label>
    <div class="semicircle"></div>
    <nav v-if="$store.state.user.roles.includes('ROLE_EXPERT')" v-show="visible" class="nav semi-circle-nav">
      <ul lang='el'>
        <a @click="$router.push({name: 'expert-news', params:{serviceId: null, type:'offer'} }), $refs.actionButton.click()"><i class="fas fa-search icon-inline"></i>{{this.$t('pages.News')}}</a>
        <a @click="$router.push({name: 'expert-statistics' }), $refs.actionButton.click()"><i class="fas fa-chart-pie"></i>{{this.$t('pages.Statistics')}}</a>
<!--        <a @click="$router.push({name: 'expert-subscription'}), $refs.actionButton.click()"><i class="fab fa-cc-visa icon-inline"></i>{{this.$t('userMenu.subscription')}}</a>-->
      </ul>
    </nav>
    <!-- <nav v-else v-show="visible" class="nav semi-circle-nav">
      <ul class="w-100 mav-header"><a class="header-label">{{this.$t('labels.create')}}</a>
      </ul>
      <ul>
        <a @click="$router.push({name: 'mobile-categories'}), $refs.actionButton.click()"><i class="fas fa-plus-circle"></i> {{this.$t('labels.instantService')}}</a>
        <a @click="$router.push({name: 'add-task', params:{serviceId: null, type:'instant'} }), $refs.actionButton.click()"><i class="fas fa-plus-circle"></i> {{this.$t('labels.instantService')}}</a>
        <a @click="$router.push({name: 'add-task', params:{serviceId: null, type:'offer'} }), $refs.actionButton.click()"><i class="fas fa-plus-circle"></i> {{this.$t('labels.offerTask')}}</a>
      </ul>
    </nav> -->
  </div>
</template>

<script>

export default {
  data() {
    return {
      visible: false,
      navPadding: this.$store.state.user.roles.includes('ROLE_EXPERT') ? '10px' : ''
    }
  },
  mounted() {
    //this.$refs.actionButton.click();
  },
  methods: {
    changeVisibility(){
      if(this.$store.state.user.roles.includes('ROLE_USER')) {
        this.$router.push({name: 'mobile-categories'});
      }
      this.visible = (!this.visible);
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 9%;
  z-index: 100;
}

.button-container label.button {
  bottom: 0.5%;
}

.button-container label.button-expert {
  bottom: 0.5%;
}

nav.semi-circle-nav {
  width: 500px !important;
}

nav.semi-circle-nav:before {
  left: calc(50% - 10px);
  bottom: -8px;
}

nav.semi-circle-nav.d-none {
  @-prefix-keyframes slide {
    to {height: 300px;}
  }
}

nav.semi-circle-nav:before {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background: #fff;
  position: absolute;
  z-index: 998;
}
#toggle {
  -webkit-appearance: none;
}

.button {
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
  position: fixed;
  z-index: 999;
  width: 43px;
  height: 43px;
  background: var(--theme-color);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 2.7%;

  &:before {
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight:900;
    color:white;
    font-size: 18px;
    // transform: rotate(30deg);
    // transition: all 0.4s ease;
  }


  // &:before {
  //   position: absolute;
  //   content: "";
  //   width: 20px;
  //   height: 2px;
  //   background: #fff;
  //   transform: rotate(90deg);
  //   transition: all 0.4s ease;
  // }

  // &:after {
  //   position: absolute;
  //   content: "";
  //   width: 20px;
  //   height: 2px;
  //   background: #fff;
  //   transition: all 0.4s ease;
  // }
}

.button-expert {
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
  position: fixed;
  z-index: 999;
  width: 43px;
  height: 43px;
  background: var(--theme-color);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 2.7%;

  &:before {
    font-family: "Font Awesome 5 Free";
    content: "\f0ad";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight:900;
    color:white;
    font-size: 18px;
    transform: rotate(270deg);
    transition: all 0.4s ease;
  }
}

.nav {
  opacity: 0;
  transition: all 0.4s ease-in-out;
  background: #fff;
  width: 60%;
  border-radius: 5px;
  transform: translateY(10%);
  box-shadow: 2px 3px 10px 0 rgba(81, 81, 81, 0.1);
  border: 1px solid #e4e4e4;
  padding: v-bind(navPadding);
  z-index: 1;
  bottom: 11px;
  position: absolute;

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  ul a {
    color: var(--theme-color);
  }

  a {
    text-align: center;
    margin: 10px 0;
    color: var(--theme-color);
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;

    &:hover {
      color: var(--theme-color);
    }
  }
}

#toggle:checked ~ .nav {
  opacity: 1;
  // transform: translateY(-100%);
}

// #toggle:checked ~ .button:before {
//   transform: rotate(225deg);
// }

// #toggle:checked ~ .button:after {
//   transform: rotate(135deg);
// }

#toggle:checked ~ .button-expert:before {
  transform: rotate(90deg);
}

#toggle:checked ~ .button-expert {
  transform: rotate(90deg);
}

.icon-inline {
  padding-right: 8px;
  font-size: 17px;
}

@media (max-width: 640px) {
  .container {
    width: 100%;
  }
}

.semicircle {
  bottom: -23px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 51px;
  width: 75px;
  border-radius: 0 0 105px 105px;
  background-color: #fefefe;
  border: 1px solid #00000040;
  border-top: 2px solid white;
}

.mav-header {
  background-color: var(--theme-color);
  opacity: 0.7;
  color: white;
}
.header-label {
  color: white !important;
}

</style>