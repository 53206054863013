import {io} from 'socket.io-client';
import {store} from '@/store'
const config = require('../../config');

class SocketioService {
    socket;

    constructor() {
    }

    setupSocketConnection(sessionID = null) {
        // this.socket = io(process.env.VUE_APP_SOCKET_ENDPOINT);npm install socket.io-client
        let authData = {
            token: store.state.user.accessToken,
        }
        if(sessionID !== null) {
            authData.sessionID = sessionID;
        }
        this.socket = io(`${config.API_URL}:${config.API_PORT}`, {
            auth: authData,
            //transports:['websocket']
        });
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    getSocketInstance() {
        return this.socket;
    }

    onSession() {
        let self = this;
        this.socket.on("session", ({sessionID, userID}) => {
            // attach the session ID to the next reconnection attempts
            this.socket.auth = {sessionID};
            // store it in the localStorage
            localStorage.setItem("sessionID", sessionID);
            // save the ID of the user
            this.socket.userID = userID;
            //console.log(sessionID,userID)
            let locale =  window.localStorage.getItem('language');
            if(!locale) {
                locale = 'en';
            }
            self.socket.emit('set-locale',{locale:locale});
        });
    }
}

export default new SocketioService();