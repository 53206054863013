<template>
    <!-- <MainLayout /> -->
    <LoginComponent/>
    <router-view :key="this.$route.fullPath"></router-view>

</template>

<script>
    import LoginComponent from "@/components/auth/LoginComponent";
    import MainLayout from "@/layouts/MainLayout";
    import CategoryService from "@/services/CategoryService"
    import TaskService from "@/services/TaskService"
    import OfferService from "@/services/OfferService";
    import UserService from "@/services/UserService"
    import SocketIO from "@/services/SocketIO"
    import EventBus from "@/helpers/EventBus"
    import AuthenticationService from "@/services/AuthenticationService"
    import ChatService from "@/services/ChatService"
    import helpers from "@/helpers/helpers"
    import {provide} from 'vue'
    import firebaseMessaging from './plugins/firebase'
    import {getToken, getMessaging, onMessage} from "firebase/messaging";
    import platform from 'platform-detect'
    import {PushNotifications} from '@capacitor/push-notifications';
    import NotificationService from "@/services/NotificationService";
    import {App} from "@capacitor/app";
    import Notify from "@/helpers/Notify";
    import {useSeoMeta, useHead} from '@unhead/vue'
    import { getActiveHead } from 'unhead';
    import {useRoute} from "vue-router";


    export default {
        setup(){
            const route = useRoute();
            const defaultTags = {
                title: 'Κάντε τη δουλειά σας σωστά με το Instaservice - η πλατφόρμα που σας βρίσκει τον κατάλληλο επαγγελματία για κάθε εργασία!',
                description: 'Βρες εξειδικευμένους επαγγελματίες για να αναλάβουν τις εργασίες που χρειάζεσαι. Πάρε Προσφορές και επίλεξε τον κατάλληλο επαγγελματία για την εργασία σου!',
                ogTitle: 'Instaservice - η πλατφόρμα που σας βρίσκει τον κατάλληλο επαγγελματία για κάθε εργασία!',
                url: 'instaservice.com.cy'
            }
            let urlParam = ';'
            if((route.path)) {
                urlParam = route.path;
            }

            window.getActiveHead = getActiveHead;
            useHead({
                title: 'InstaService | %title',
                templateParams: {
                    title: defaultTags.title,
                    description: defaultTags.description,
                    ogTitle: defaultTags.ogTitle,
                    url: urlParam
                },
                meta: [
                    {
                        name: 'description',
                        content: 'InstaService | %description'
                    },
                    {
                        name: 'og:title',
                        content: 'InstaService | %ogTitle'
                    },
                    {
                        name: 'og:url',
                        content: `${defaultTags.url}%url`
                    },
                    {
                        name: 'og:type',
                        content: 'website'
                    },
                    {
                        name: 'og:site_name',
                        content: 'InstaService'
                    },
                    {
                        name: 'og:image',
                        content: 'https://instaservice.com.cy/blog/wp-content/uploads/2023/08/final-logo_hdj5wy.png'
                    },
                    {
                        name: 'og:image:secure_url',
                        content: 'https://instaservice.com.cy/blog/wp-content/uploads/2023/08/final-logo_hdj5wy.png'
                    },
                    {
                        name: 'og:image:type',
                        content: 'image/png'
                    },
                    {
                        name: 'og:image:width',
                        content: '1500'
                    },
                    {
                        name: 'og:image:height',
                        content: '300'
                    },
                    {
                        name: 'twitter:image',
                        content: 'https://instaservice.com.cy/blog/wp-content/uploads/2023/08/final-logo_hdj5wy.png'
                    }
                ]
            })
        },
        components: {
            LoginComponent
            // MainLayout
        },
        watch: {
            '$store.state.user': function (val) {
                const sessionID = localStorage.getItem("sessionID");
                //alert('STATE OF USER IS '+ val.id)
                if (val !== null && val.id !== null) {
                    //alert('VAL SOCKETS INIT?')
                    //SocketIO.setupSocketConnection(sessionID);
                    this.registerListeners();
                    SocketIO.onSession();
                    //update fcm token
                }
            },
            '$store.state.currentUser': function (val) {
                if (val !== null) {
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                }
            },
            '$store.state.fcmToken': function (token) {
                if (platform.packaged && this.$store.getters.getUser.id != null && this.$store.state.currentUser == null) {
                    //if token is updated, and user is logged in, update on server as well
                    NotificationService.setDeviceToken(this.$store.getters.getUser.id.id, token);
                }
            },
            '$i18n.locale': function () {
                this.$store.dispatch("setLocale", this.$i18n.locale);
            }
        },
        data() {
            return {
                helpers,
                STATE_POLLING_INTERVAL: 1000 * 60 * 60, // 1 hour
                polling: null,
                updateStatePolling: null,
                appBackground: this.$isMobile() ? '#f7f7f7' : '#F8FCFD',
                lastTranslationsUpdate: null
            }
        },
        created() {
            // Initial device detection and storage
            this.$store.dispatch("setIsMobile", this.$isMobile());
            this.$store.dispatch("setLocale", this.$i18n.locale);

            EventBus.$on("DISCONNECT_SOCKET", (resp) => {
                SocketIO.disconnect();
            });
            EventBus.$on("RELOAD_COUNTERS", (counter) => {
                this.getUnreadTaskRequetsCounter();
                if (this.$store.state.user.roles.includes('ROLE_EXPERT')) {
                    this.getExpertUnreadOffersCounter();
                } else {
                    this.getUserUnreadOffersCounter();
                }
            });

            //console.log('SERVICVES = ', this.$store.getters.getCategoriesServices);
            this.getCategoriesServices();
            this.updateCategoriesServices();

            if (platform.packaged && this.$store.state.currentUser == null) {
                const pushListeners = async () => {
                    await PushNotifications.addListener('registration', token => {
                        console.log("=========================== PUSH NOTIFICATIONS ==========================");
                        console.info('Registration token: ', token.value);
                        this.$store.dispatch("setFcmToken", token.value);
                        // alert(this.$store.getters.getFcmToken);
                        console.log("=========================================================================");

                    });

                    await PushNotifications.addListener('registrationError', err => {
                        console.error('Registration error: ', err.error);
                    });

                    await PushNotifications.addListener('pushNotificationReceived', notification => {
                        console.log('Push notification received: ', notification);
                    });

                    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
                        console.log('Push notification action performed', notification.actionId, notification.inputValue);
                    });

                }
                const registerNotifications = async () => {
                    let permStatus = await PushNotifications.checkPermissions();

                    if (permStatus.receive === 'prompt') {
                        permStatus = await PushNotifications.requestPermissions();
                    }

                    // if (permStatus.receive !== 'granted') {
                    //   throw new Error('User denied permissions!');
                    // }

                    await PushNotifications.register();
                }
                registerNotifications();
                pushListeners();
            }
            let self = this;
            //reset listeners and user token when app resumes from sleep state
            App.addListener('resume', () => {
                console.log('app was resumed')
                self.checkUserLoginStatus();
                if (!this.lastTranslationsUpdate) {
                    this.lastTranslationsUpdate = (new Date());
                }
                let time = new Date();

                let diffInMillSecs = Math.abs(time - this.lastTranslationsUpdate);
                //re-fetch translation if more than 4 hours have passed since last time
                if ((Math.floor((diffInMillSecs / 1000) / 60)) > 240) {
                    console.log('re-getting translations')
                    self.fetchTranslations(self);
                }
            });
        },
        mounted() {
            this.setUtms();
            this.fetchTranslations();

            //console.log('IS FB ok? : ', this.isWindowControllerSupported());
            //TODO: Find a way to refresh store user data that are not static (i.e: user total rating (it changes after a review is made)
            //Idea from checks came from :
            // - https://github.com/firebase/firebase-js-sdk/blob/0beb028de38c74198592f47618400f9905d605da/packages/messaging/index.ts#L86
            // - https://stackoverflow.com/questions/54246667/firebase-cloud-messaging-works-only-from-localhost
            if (!platform.packaged && this.isWindowControllerSupported()) {
                const messaging = getMessaging();
                onMessage(messaging, (payload) => {
                    //console.log('Payload received is:', payload);
                    //TODO: check if the message is for the current logged in user ( as on web multiple users can login from the same machine and have the same token)
                })
            }
            this.registerListeners();
        },
        beforeUnmount() {
            //clear all listeners before unmounting
            if (SocketIO.getSocketInstance()) {
                SocketIO.getSocketInstance().removeAllListeners();
                SocketIO.disconnect();
            }
            clearInterval(this.polling);
        },
        methods: {
            forceUpdateApp() {
                this.$forceUpdate();
            },
            fetchTranslations(instance) {
                let self = (instance) ? instance : this;
                window.i18n = self.$i18n;
                //import task question translations
                TaskService.getTaskQuestionTranslations().then(response => {
                    Object.entries(response.data).forEach((entry) => {
                        const [locale, value] = entry;
                        let index = self.$i18n.availableLocales.indexOf(locale);
                        console.log('index ' + index)
                        if (index >= 0) {
                            Object.entries(value).forEach((entry) => {
                                const [key, value] = entry;
                                self.$i18n.messages[locale][key] = value
                            });
                        }
                    });
                })
            },
            updateCategoriesServices() {
                this.polling = setInterval(() => {
                    // Update every hour
                    if (this.$store.getters.getCategoriesServices == null || new Date().getTime() - this.$store.getters.getCategoriesServices.timeUpdated > 60 * 60 * 1000) {
                        this.getCategoriesServices();
                    }
                }, 5000);
            },
            async getCategoriesServices() {
                try {
                    await CategoryService.getCategoriesServices().then((resp) => {
                        this.$store.dispatch("setCategoriesServices", resp.data);
                    });
                } catch (error) {
                    Notify.displayError(error.response.data.message);
                }
            },
            async getUnreadChatroomCounter() {
                try {
                    await ChatService.getUnreadChatroomCounter().then((resp) => {
                        this.$store.dispatch("setUnreadChatroomsCount", resp.data.count);
                    });
                } catch (error) {
                    Notify.displayError(error.response.data.message);
                }
            },
            async getUnreadTaskRequetsCounter() {
                try {
                    await TaskService.getUnreadTaskRequetsCounter().then((resp) => {
                        this.$store.dispatch("setUnreadTaskRequestsCount", resp.data.counter);
                    });
                } catch (error) {
                    Notify.displayError(error.response.data.message);
                }
                console.log('STATE COUNT', this.$store.state);
            },
            async getUserUnreadOffersCounter() {
                try {
                    await TaskService.getUnreadOffersCounter().then((resp) => {
                        this.$store.dispatch("setUnreadOffersCount", resp.data.counter);
                    });
                } catch (error) {
                    Notify.displayError(error.response.data.message);
                }
            },
            async getExpertUnreadOffersCounter() {
                try {
                    await OfferService.getExpertOfferNews({page: 1, distance: 60, fetchAll: true}).then((resp) => {
                        if (resp.data.expertNews.length > 0) {
                            this.$store.dispatch("setUnreadOffersCount", resp.data.expertNews.length);
                        }
                    });
                } catch (error) {

                }
            },
            isWindowControllerSupported() {
                return (
                    navigator.cookieEnabled &&
                    'serviceWorker' in navigator &&
                    'PushManager' in window &&
                    'Notification' in window &&
                    'fetch' in window &&
                    ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
                    PushSubscription.prototype.hasOwnProperty('getKey')
                );
            },
            async updateUserState() {
                try {
                    await UserService.updateUserState().then((resp) => {
                        this.$store.dispatch('updateUserStateByKeys', {
                            hasActiveSubscription: resp.data.hasActiveSubscription,
                            totalRating: resp.data.rating.totalRating,
                            remainingBonusTasks: resp.data.remainingBonusTasks
                        });
                    });
                } catch (error) {
                    Notify.displayError(error.response.data.message);
                }
            },
            registerListeners() {
                const sessionID = localStorage.getItem("sessionID");
                if (this.$store.state.user && this.$store.state.user.id !== null) {
                    AuthenticationService.refreshAccessToken(this.$store.state.user.refreshToken).then((resp) => {
                        console.info('USER TOKEN WAS UPDATED!!!!!!!!!')
                        let token = resp.data.accessToken;
                        this.$store.dispatch("setToken", token);
                    }, err => {
                        console.error('AUTH TOKEN REFRESH ERROR', err)
                        helpers.logout(true);
                        return;
                    });
                    SocketIO.setupSocketConnection(sessionID);
                    SocketIO.onSession();

                    // Get unread chatrooms counter
                    this.getUnreadChatroomCounter();
                    // Get unread task  for both user types
                    this.getUnreadTaskRequetsCounter();
                    if (this.$store.state.user.roles.includes('ROLE_EXPERT')) {
                        this.getExpertUnreadOffersCounter();
                    } else {
                        this.getUserUnreadOffersCounter();
                    }
                    // Update user state
                    this.updateUserState();
                    if (!this.updateStatePolling) {
                        this.updateStatePolling = setInterval(() => {
                            this.updateUserState();
                        }, this.STATE_POLLING_INTERVAL);
                    }
                    var ref = this;
                    // Update Messages counter ** TO-DO (maybe change approach)
                    SocketIO.getSocketInstance().on('received-chat-message', function (data) {
                        console.log('CHAT DATA', data)
                        ref.getUnreadChatroomCounter();
                        if (ref.$route.path == '/user-chat') {
                            EventBus.$emit('reload-user-chat');
                        }
                    });

                    //handle socket connection error due to expired token
                    SocketIO.getSocketInstance().on('connect_error', (err) => {
                        if (err instanceof Error && typeof err.message !== 'undefined' && err.message === 'Authentication error') {
                            SocketIO.disconnect();
                            AuthenticationService.refreshAccessToken(this.$store.state.user.refreshToken).then((resp) => {
                                let token = resp.data.accessToken;
                                this.$store.dispatch("setToken", token);
                            }, err => {
                                helpers.logout(true);
                                return;
                            });
                            SocketIO.setupSocketConnection(sessionID);
                            SocketIO.onSession();
                        } else {
                            alert('Lost connection to the server. Please reload application')
                            console.error('Cannot connect to socket, received ', err);
                        }
                    });

                    //if server gets restarted, disconnect current session and try to reconnect
                    SocketIO.getSocketInstance().on('disconnect', function (data) {
                        //const sessionID = localStorage.getItem("sessionID");
                        console.log('SERVER DISCONNECTED', data);
                        //let self = this;
                        SocketIO.getSocketInstance().disconnect();
                        if (ref.$store.state.user && ref.$store.state.user.id && data !== 'io client disconnect') {
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                        //reload app id server is restarted to reconnect to session
                        // TODO: find out why when reconnecting to server client is not listening correctly to emmited events from server and do a proper session reload without refreshing window
                    });
                }
            },
            checkUserLoginStatus() {
                if (this.$store.state.user && this.$store.state.user.id !== null) {
                    if (SocketIO.getSocketInstance()) {
                        SocketIO.getSocketInstance().removeAllListeners();
                        this.registerListeners();
                    }
                }
            },
            setCookie(cname, cvalue) {
                const d = new Date();
                d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000); //add 7 days to cookie life
                const expires = `expires=${d.toUTCString()}`;
                document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=Strict`; // Using the new SameSite attribute: https://web.dev/samesite-cookie-recipes/
                document.cookie = `${cname}=${cvalue};${expires};path=/;Secure`; // Fallback for legacy browsers: https://web.dev/samesite-cookie-recipes/
            },
            getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(";");
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == " ") {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            setUtms() {
                if (this.$store.state.currentUser !== null) {
                    return;
                }
                let params = (new URL(document.location)).searchParams;
                let utm_source = this.getCookie("utm_source") !== "" ? this.getCookie("utm_source") : this.$route.query.utm_source ? this.$route.query.utm_source : params.get('utm_source');
                let utm_content = this.getCookie("utm_content") !== "" ? this.getCookie("utm_content") : this.$route.query.utm_content ? this.$route.query.utm_content : params.get('utm_content');
                let utm_campaign = this.getCookie("utm_campaign") !== "" ? this.getCookie("utm_campaign") : this.$route.query.utm_campaign ? this.$route.query.utm_campaign : params.get('utm_campaign');
                let utm_term = this.getCookie("utm_term") !== "" ? this.getCookie("utm_term") : this.$route.query.utm_term ? this.$route.query.utm_term : params.get('utm_term');
                let utm_medium = this.getCookie("utm_medium") !== "" ? this.getCookie("utm_medium") : this.$route.query.utm_medium ? this.$route.query.utm_medium : params.get('utm_medium');
                let gclid = this.getCookie("gclid") !== "" ? this.getCookie("gclid") : this.$route.query.gclId ? this.$route.query.gclId : params.get('gclId');

                if (utm_source) this.setCookie("utm_source", utm_source);
                if (utm_content) this.setCookie("utm_content", utm_content);
                if (utm_campaign) this.setCookie("utm_campaign", utm_campaign);
                if (utm_term) this.setCookie("utm_term", utm_term);
                if (utm_medium) this.setCookie("utm_medium", utm_medium);
                if (gclid) this.setCookie("gclid", gclid);
            },
        }
    }
</script>
<style>
    /* #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
    }

    nav {
      padding: 30px;
    }

    nav a {
      font-weight: bold;
      color: #2c3e50;
    }

    nav a.router-link-exact-active {
      color: #42b983;
    } */
    #app {
        font-family: "Poppins", sans-serif;
    }

    .container {
        max-height: 100% !important;
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .body-two {
        background-color: v-bind(appBackground) !important;
    }
</style>
