import Api from './Api'

export default {
    updateUserProfile(userId, data) {
        return Api().post(`/api/users/${userId}`, data)
    },
    getUserDetailsById(userId) {
        return Api().get(`/getUserDetailsById/${userId}`)
    },
    favouritesAction(expertId) {
        return Api().post('/favouritesAction', expertId)
    },
    getUserFavourites() {
        return Api().get('/getUserFavourites')
    },
    getUserReviews() {
        return Api().get('/getUserReviews')
    },
    getAllUsers() {
        return Api().get('/getAllUsers')
    },
    reviewUser(data) {
        return Api().post(`/reviewUser`, data)
    },
    getUserReviewsByRevieweeAndTask(userId, revieweeId, taskId) {
        return Api().get(`/user/${userId}/review/user/${revieweeId}/task/${taskId}`);
    },
    getUserReportReasons() {
        return Api().get(`/report/reasons`);
    },
    reportUser(data) {
        return Api().post(`/user/report`, data);
    },
    updateUserState() {
        return Api().get('/updateUserState');
    },
    getUserServices() {
        return Api().get('/getUserServices');
    },
    updateUserServices(serviceIds) {
        return Api().post('updateUserServices', serviceIds)
    }
}
