import Api from './Api'

export default {
    createTask(details) {
        return Api().post('/createTask', details)
    },
    getUserTasks(userId, status = null, page  = 1) {
        return Api().get(`/users/${userId}/tasks/${status}?page=${page}`)
    },
    getTaskById(taskId) {
        return Api().get(`/tasks/${taskId}`);
    },
    taskBelongsToUser(taskId) {
        return Api().post('/taskBelongsToUser', taskId);
    },
    getCancellationReasonsByUserRole(role) {
        return Api().get(`/getCancellationReasonsByUserRole/${role}`)
    }, 
    cancelTask(userId, details) {
        return Api().post(`/cancelTask/users/${userId}`, details)
    },
    getExpertTaskHistory() {
        return Api().get('/getExpertTaskHistory')
    },
    getTaskHistoryByTaskId(id) {
        return Api().get(`/getTaskHistoryByTaskId/${id}`)
    },
    deleteTaskImage(taskId, imageId){
        return Api().post(`deleteTaskImage/${taskId}/${imageId}`)
    },
    editTask(details){
        return Api().post('/editTask', details)
    },
    getUnreadTaskRequetsCounter() {
        return Api().get('getUnreadTaskRequetsCounter')
    },
    getUnreadOffersCounter() {
        return Api().get('getUnreadOffersCounter')
    },
    getTaskQuestionTranslations() {
        return Api().get("/translations");
    }
}
