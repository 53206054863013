<template>
  <!-- Login Modal -->
  <GDialog v-model="openLoginModal">
    <div class="account-modal" id="login_modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header p-0 border-0">
            <button type="button" class="close" @click="closeDialog()"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="login-header text-center">
               <span class="navbar-brand logo">
                  <img src="@/assets/img/final-logo.png" style="margin-bottom: 12px" class="img-fluid" alt="Logo" />
                </span>
            </div>
            <div class="login-tabs text-center mt-3">
              <ul>
                <li>
                  <a role="button" @click="this.activeContainer = 'login'; this.errors = {}, this.loginAs = null" :class="this.activeContainer == 'login' ? 'active' : ''">
                    <i class="fas fa-lock me-1"></i> {{ this.$t('login.login') }}
                  </a>
                </li>
                <li>
                  <a role="button" @click="this.activeContainer = 'register'; this.errors = {}" :class="this.activeContainer == 'register' ? 'active' : ''">
                    <i class="fas fa-user-lock me-1"></i> {{ this.$t('login.signup') }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="text-danger">{{errors.message}}</div>
            <div v-if="this.activeContainer == 'login'" class="login-container">
              <div v-if="!loginAs">
                <buton @click="this.loginAs = 'user'; this.loginType = 'email'" class="d-grid btn btn-primary btn-block btn-lg">{{this.$t('login.loginAsUser')}}</buton>
                <buton @click="this.loginAs = 'expert'; this.loginType = 'email'" class="d-grid mt-4 btn btn-primary btn-block btn-lg">{{ this.$t('login.loginAsExpert') }}</buton>
              </div>
              <div v-else>
                <div v-if="this.loginType == 'email'" id="email-login">
                  <div class="form-group form-focus">
                    <label class="focus-label">{{this.$t('formFields.email')}}</label>
                    <input @keyup.enter="login()" v-model="userCredentials.email" type="email" class="form-control" placeholder="instaservices@example.com" />
                    <span class="text-danger">{{ errors.email }}</span>
                  </div>
                  <div class="form-group form-focus">
                    <label class="focus-label">{{this.$t('formFields.password')}}</label>
                    <input @keyup.enter="login()" v-model="userCredentials.password" type="password" class="form-control" placeholder="********" />
                    <span class="text-danger">{{ errors.password }}</span>
                  </div>
                  <div class="text-end"></div>
                  <div class="d-grid">
                    <button @click="login()" class="btn btn-primary btn-block btn-lg login-btn">{{ this.$t('login.login') }}</button>
                  </div>
                  <div v-if="this.loginAs == 'user'" class="form-group form-focus">
                    <div style="width: 100%; height: 20px; border-bottom: 1px solid black; text-align: center; margin-bottom:10px;position:relative">
                      <span style="font-size: 12px; background:white; padding: 2px 10px;width:100%;text-align:center">
                        {{this.$t('login.orLoginOptions')}}
                      </span>
                    </div>
                    <button @click="changeToPhone" class="w-100 d-grid btn btn-outline-secondary btn-block btn-lg login-btn">
                      <span class="d-inline"><svg type="image/svg+xml" viewBox="956 528.1576232910156 24 24" width="16" height="16" fill="none" class="d-inline" style="min-width: 16px; min-height: 16px; margin: 0px 2px 0px 4px;"><g id="icons / 24 px / review">
                        <g id="Group 26333">
                          <path id="Vector_54" d="M970 546.923C973.771 546.923 975.657 546.923 976.828 545.751C978 544.58 978 542.694 978 538.923C978 535.152 978 533.266 976.828 532.095C975.657 530.923 973.771 530.923 970 530.923H966C962.229 530.923 960.343 530.923 959.172 532.095C958 533.266 958 535.152 958 538.923C958 542.694 958 544.58 959.172 545.751C959.825 546.405 960.7 546.694 962 546.821" stroke="#040C15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path id="Vector_55" d="M970 546.923C968.764 546.923 967.402 547.423 966.159 548.068C964.161 549.105 963.162 549.624 962.67 549.293C962.178 548.963 962.271 547.938 962.458 545.889L962.5 545.423" stroke="#040C15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                        <line id="Line 8" x1="962.75" y1="536.25" x2="973.25" y2="536.25" stroke="#040C15" stroke-width="1.5" stroke-linecap="round"></line>
                        <line id="Line 9" x1="962.75" y1="540.25" x2="970.25" y2="540.25" stroke="#040C15" stroke-width="1.5" stroke-linecap="round"></line>
                      </g></svg>{{ this.$t('login.loginViaPhone') }} </span></button>
                    <button class="btn btn-outline-secondary w-100 mt-2" @click="this.loginAs = null;this.loginType=null;"> <i class="fa fa-arrow-left"></i> {{ this.$t('Back')}}</button>
                  </div>
                  <button v-if="this.loginAs==='expert' && this.loginType==='email'" class="btn btn-outline-secondary w-100 mt-2" @click="this.loginAs = null;this.loginType=null;"> <i class="fa fa-arrow-left"></i> {{ this.$t('Back')}}</button>
                </div>
                <div v-if="this.loginType == 'phone'">
                  <OtpLogin @otpVerifyCall="proceedToLogin" :show-heading="false"></OtpLogin>
                  <div class="row mt-3">
                    <div class="d-grid">
                      <button @click="this.loginType='email'" class="btn btn-outline-secondary btn-block btn-lg login-btn"> <i class="fa fa-arrow-left"></i> {{ this.$t('login.loginViaEmail') }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="login-or">
                <span class="or-line"></span>
                <span class="span-or">or</span>
              </div>
              <div class="row form-row social-login">
                <div class="col-6 d-grid">
                  <a href="#" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f me-1"></i> Login</a>
                </div>
                <div class="col-6 d-grid">
                  <a href="#" class="btn btn-google btn-block"><i class="fab fa-google me-1"></i> Login</a>
                </div>
              </div>
              <div class="text-center dont-have">Don't have an account? <a href="#">Sign up</a></div> -->
              <div class="text-center dont-have">{{$t('labels.forgotPassword')}} <a class="cursor-pointer" @click="openLoginModal = false, $router.push({ name:'forgot-password' })">{{$t('labels.Click here')}}</a></div>
            </div>
            <!-- End Login Container -->

            <!-- Register Container -->
            <div v-if="this.activeContainer == 'register'"  >
              <div class="d-grid mt-4">
                <button @click="closeDialog(), $router.push('/mobile-user-register')" class="btn btn-primary btn-block btn-lg">{{$t('login.joinAsUser')}}</button>
              </div>
              <div class="d-grid mt-4">
                <button @click="closeDialog(), $router.push('/register-professional')" class="btn btn-primary btn-block btn-lg">{{$t('login.joinAsProfessional')}}</button>
              </div>
            </div>
            <!-- End Register Container -->

          </div>
        </div>
      </div>
    </div>
  </GDialog>
  <!-- /Login Modal -->
</template>
<script>
import EventBus from "@/helpers/EventBus";
import Notify from "@/helpers/Notify";
import { GDialog } from "gitart-vue-dialog";
import 'gitart-vue-dialog/dist/style.css'
import AuthenticationService from "@/services/AuthenticationService";
import {getToken} from "firebase/messaging";
import NotificationService from "@/services/NotificationService";
import OtpLogin from "@/components/auth/OtpLogin.vue";

export default {
  components: {
    GDialog,
    OtpLogin
  },
  data() {
    return {
      activeContainer: 'login',
      openLoginModal: false,
      userCredentials: {
        email: null,
        password: null,
      },
      userRegistration: {
        firstName: null,
        lastName: null,
        email: null,
        homeAddress: null,
        password: null,
        passwordConfirm: null,
        roles: 'user'
      },
      errors: {
        email:'',
        password:'',
        message: ''
      },
      nextPath: null,
      nextPathName: null,
      nextPathParms: {},
      usingParams: false,
      phoneOtpLogin: {
        show: false,
        otpStep: false,
      },
      loginType: 'selection',
      loginAs: null
    };
  },
  mounted() {
    EventBus.$on("OPEN_LOGIN_POPUP", (resp) => {
      //clear errors
      this.errors = {};
      this.openLoginModal = true;
      this.nextPath = resp !== undefined && resp.nextPath !== undefined ? resp.nextPath : null;
      this.nextPathName = resp !== undefined && resp.nextPathName !== undefined ? resp.nextPathName : null;
      this.nextPathParams = resp !== undefined && typeof resp.nextPathParams === 'object' ? resp.nextPathParams : null;
      this.usingParams = resp !== undefined && resp.usingParams == true ? true : false;
window.tis = this;
    });
  },
  watch: {
    openLoginModal: function (val) {
      if(!val) {
        this.loginAs = null;
        this.loginType = 'selection';
      }
    }
  },
  methods: {
    async login() {
      //clear errors
      this.errors = {};
      try {
        const response = await AuthenticationService.login({
          email: this.userCredentials.email,
          password: this.userCredentials.password,
        });

        this.$store.dispatch("setUser", response.data);
        this.openLoginModal = false;
        Notify.displaySuccess(this.$t("Successfully logged in"));
        //create push notification record if not exist OR update existing record with new device token if timestamp is stale ( older than 2 weeks)
        // this.subscribe();
        EventBus.$emit("USER_LOGGEDIN");

        // Redirect where necessary
        if(this.nextPath !== null) {
          // Using Params
          if(this.usingParams) {
            this.$router.push({ name: this.nextPathName, params: this.nextPathParams });
          } else {  // Using full path
            this.$router.push({ path: this.nextPath });
          }
          return;
        }

        // Redirect expert to dashboard
        if(this.$store.state.user.roles.includes('ROLE_ADMIN')) {
          this.$router.push({ path: '/admin'});
        }
        if(this.$store.state.user.roles.includes('ROLE_EXPERT')) {
          this.$router.push({ path: '/expert-dashboard'});
        }
        if(this.$store.state.user.roles.includes('ROLE_USER')) {
          this.$router.push({ path: '/mobile-categories'});
        }
      } catch (error) {
        console.log("error", error);
        window.error = error;
        let responseData = error.response.data;
        if(typeof responseData.errors !== 'undefined') {
          if(typeof responseData.errors.email !== 'undefined') {
            this.errors.email = responseData.errors.email.msg ;
          }
          if(typeof responseData.errors.password !== 'undefined') {
            this.errors.password = responseData.errors.password.msg ;
          }
        }
        this.errors.message = (typeof responseData.errors == 'undefined' && typeof responseData.message !== 'undefined') ? responseData.message : '';
        console.log(this.errors)
        Notify.displayError(responseData.message);
      }
    },
    async register() {
      try {
        await AuthenticationService.register(this.userRegistration).then(async(resp) => {
          if(resp.status == 200) {
            try {
              await AuthenticationService.login({
                email: this.userRegistration.email,
                password: this.userRegistration.password,
              }).then((resp) => {
                this.$store.dispatch("setUser", resp.data);
                console.log("this", this.$store.getters.getUser);
                this.openLoginModal = false;
                Notify.displaySuccess(this.$t("Successfully signed up"));
              });
            } catch(err) {

            }
          }

        });
      } catch(err) {
        window.err = err;
        console.log(err)
        let errors = {};
        for(let error in err.response.data.errors) {
          if(err.response.data.errors.hasOwnProperty(error)){
            errors[error] = err.response.data.errors[error].msg;
          }
        }
        this.errors = errors;
      }
    },
    closeDialog() {
      //clear errors
      this.errors = {};
      this.openLoginModal = false;
      this.activeContainer = 'login';
    },
    subscribe() {
      getToken(this.$messaging, { vapidKey: 'BFnW-hi47DIPD1bxVevzNFmdBFNRCibqdb96slwxRkIp9uyrZvkFRIafByMLWCFRtp1WtekuIpsLfOSm7e-bgas' }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          const user = this.$store.getters.getUser;
          console.log("FCM TOKEN IS: ", currentToken);
          NotificationService.setDeviceToken(user.id, currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          this.requestPermission();
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        Notify.displayError(this.$t('labels.Notification Request has Failed'), this.$t('labels.Please grant permissions for notifications to receive updated information regarding your tasks'), 4000);
      })
    },
    requestPermission() {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.log('Notification permission NOT granted.');
          Notify.displayError(this.$t('labels.Notification Request has Failed'), this.$t('labels.Please grant permissions for notifications to receive updated information regarding your tasks'));
        }
      })
    },
    changeToPhone() {
      this.loginType = 'phone';
      console.log(this.loginType);
    },
    proceedToLogin(response) {
      if(response.loginViaPhone) {
        window.location.reload();
      }
    }
  }
};
</script>
<style scoped>
.account-modal {
  position: fixed;
  top: 48%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
</style>
