import Api from './Api'

export default {
    register(credentials) {
        return Api().post('/api/auth/signup', credentials)
    },
    login(credentials) {
        return Api().post('/api/auth/signin', credentials)
    },
    loginViaPhone(phone,data) {
        return Api().post('/api/auth/signin-mobile', {phone:phone, data:data})
    },
    signout() {
        return Api().post('/api/auth/signout')
    },
    refreshAccessToken(currentAccessToken) {
        return Api().post('/api/auth/refreshToken', {refreshToken:currentAccessToken})
    },
    passwordResetRequest(emailAddress) {
        return Api().post('/api/auth/passwordResetRequest', emailAddress)
    },
    passwordResetAction(details) {
        return Api().post('/api/auth/passwordResetAction', details)
    },
    isExpertValidated() {
        return Api().get('/isExpertValidated')
    },
    getEmailSmsOTP() {
        return Api().get('/getEmailSmsOTP')
    },
    verifyEmailSmsOTP(otpDetails) {
        return Api().post('/verifyEmailSmsOTP', otpDetails)
    },
    submitKYC(data){
        return Api().post('/submitKYC',data)
    },
    getKYCbyUserId(userId) {
        return Api().get(`/getKYCbyUserId/${userId}`)
    },
    deleteAccount(details) {
        return Api().post('/deleteAccount', details)
    },
    getAccountDeletionReasons() {
        return Api().get('/getAccountDeletionReasons')
    },
    smsOtpSend(phone) {
        return Api().post('/api/auth/otp/send/sms', {phone:phone})
    },
}
