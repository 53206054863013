<script setup>

import {ref, computed, watch, getCurrentInstance} from 'vue'
import {markRaw} from 'vue'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import parsePhoneNumber from 'libphonenumber-js/max'
window.parseNumber = parsePhoneNumber;

const instance = getCurrentInstance()
const phoneNumber = ref()
const results = ref()
const props = defineProps({
      context: Object
    }
)
// props.context.node.on('updated', () => {
//   steps[node.name].valid = toRef(node.context.state, 'valid')
// })

// watch(results, (value) => {
//   // Do something with the updated value.
//   props.context.node.input(value.e164);
// });

function checkInput(context,event){
  if(results.value) {
    props.context.node.props.isValid = results.value.isValid;
    if( results.value.e164) {
      console.log('phone input updated =>'+results.value.e164)
      let type = parsePhoneNumber(results.value.e164, results.value.countryCode).getType();
      if(typeof type === 'undefined' || type !== 'MOBILE') {
        props.context.node.props.isValid = false;
      }
      props.context.node.input(results.value.e164);
      window.phoneValue = results.value.e164;
    }
  }

}

//context._model
//@update:model-value="context.node.input($event.e164)"
//@update="results = $event, setValidation(context, $event, 'update')"
</script>
<template>
  <MazPhoneNumberInput
      v-model="phoneNumber"
      :id="context.id"
      show-code-on-list
      color="info"
      :only-countries="context.attrs['only-countries']"
      :custom-countries-list="context.attrs['custom-countries-list']"
      :translations="context.attrs['translations']"
      @update="results = $event"
      @update:model-value="checkInput(context,$event)"
      :default-country-code="context.attrs['default-country-code'] ? context.attrs['default-country-code'] : 'CY'"
  />
</template>

