<template>
  <footer id="mobileMenu" class="fixed-bottom text-white footer-background">
    <div class="footer-tabs-list">
      <div v-for="item in menuItems" :key="item.label" class="footer-tabs-content">
        <div v-if="item.iconClass === 'floating-action-button'">
          <FloatingActionButton/>
        </div>
        <div @click="navigateTo(item.pathName)" :class="isActiveItem(item.pathName) + ' ' + (item.hasOwnProperty('hasAction') ? 'underline-none':'')" class="footer-tab text-center ripple" role="tab">
          <div :class="item.hasOwnProperty('hasAction') ? 'action-item':'' " class="q-focus-helper ripple">
            <div class="footer-headers ripple"><br>{{ item.header }}</div>
          </div>
          <i :class="item.iconClass + ' ' + (item.hasOwnProperty('hasAction') ? 'action-icon':'')" class="menu-item-icon"></i>
          <span v-if="item.hasOwnProperty('counter') && item.counter > 0" :class="`badge badge-pill bg-yellow pill-position ${item.iconClass == 'fas fa-bolt' && $store.state.counters.unreadTaskRequestsCount > 0 ? 'pulsate' : ''}`">{{item.counter}}</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FloatingActionButton from "@/components/FloatingActionButton";
export default {
  props: {
    menuItems: {
      type: Array,
    },
    displayLabel: {
      type: Boolean,
    },
  },
  components: {
    FloatingActionButton,
  },
  methods: {
    isActiveItem(itemUrl) {
      if (this.$route.name == itemUrl) {
        return "active";
      }
    },
    navigateTo(pathName) {
      switch(pathName) {
        case 'expert-dashboard':
          this.$store.dispatch('setMenuTabSelection', {tabName: 'expertInstant', tabIndex: 'tab1'});
          break;
        case 'user-dashboard':
          this.$store.dispatch('setMenuTabSelection', {tabName: 'userInstant', tabIndex: 'tab1'});
          break;
        case 'expert-offers':
          this.$store.dispatch('setMenuTabSelection', {tabName: 'expertOffers', tabIndex: 'tab1'});
          break;
        case 'user-offers':
          this.$store.dispatch('setMenuTabSelection', {tabName: 'userOffers', tabIndex: 'tab1'});
          break;
      }
      this.$router.push({ name: pathName });
    },
  },
};
</script>

<style scoped>
/* Mobile Footer Menu Styles */
.footer-background {
  /* background-color: var(--theme-color); */
  background-color: var(--theme-color);
}
.fixed-bottom {
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
}
.text-white {
  color: #fff !important;
}
.footer-tabs-list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
  color: #fff !important;
  position: relative;
  transition: color 0.3s, background-color 0.3s;
  background-color: white;
}

.footer-tabs-content {
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  color: var(--mobile-menu-text);
}

.footer-tab {
  flex: 1 1 auto;
  min-height: 52px;
  position: relative;
  cursor: pointer !important;
  align-items: center;
  justify-content: center;

  padding: 10px 16px;
  min-height: 54px;
  transition: color 0.3s, background-color 0.3s;
  /* text-transform: uppercase; */
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
}


.footer-tabs-content .active {
  background-color: white !important;
  color:var(--theme-color);
  /*border-bottom: 2px solid var(--theme-color);*/
}

.q-focus-helper {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  /* opacity: 0; */
}

.menu-item-icon {
  font-size: 19px;
}

.action-item {
  border: 1px solid #4a9592;
  border-radius: 38%;
  background-color: #4a9592;
  height: 49px;
  /* width: 53px; */
  /* margin-left: 14%; */
}
.action-icon {
    /* margin-left: -34%; */
    z-index: 100;
    position: relative;
    color: white;
     margin-left: 7%;
}

.underline-none {
  border-bottom: none !important;
}
.pill-position {
  position: absolute;
}
.footer-headers {
  font-size: 11px;
  margin-top: 17px;
}

.main-button.menu-item-icon{
  color: var(--theme-color);;
  margin-top: -5px;
  font-size: 30px;
}
  @media screen and (max-width: 360px){
    .footer-headers{
      font-size: 11px;
      margin-top: 15px;
    }
  }
</style>
