import Api from './Api'

export default {
    getCategories() {
        return Api().get('/categories')
    },
    getCategoriesServices() {
        return Api().get('/categoriesServices')
    },
    getServices() {
        return Api().get('/services')
    },
    getServicesWithExpertCounts() {
        return Api().get('/getServicesWithExpertCounts')
    }
}
