<template> 
    <GDialog v-model="subscriptionAlert" max-width="500" @update:modelValue="subscriptionAlert = false">
        <div class="wrapper-confirm">
            <div class="content-confirm">
            <div class="title-confirm">error</div>
            <p>Subscription error</p>
            </div>
            <div class="dialog-actions">
            <div class="formkit-outer text-end" data-family="button" data-type="submit">
                <div class="formkit-wrapper">
                <button @click="subscriptionAlert = false" class="formkit-input formkit-input-outline">{{ this.$t("taskFields.close") }}</button>
                </div>
            </div>
            </div>
        </div>
    </GDialog>
</template>

<script>  
import SocketIO from "@/services/SocketIO";
import {GDialog} from "gitart-vue-dialog";
import socketIO from "@/services/SocketIO";

export default {
    components: {
        GDialog
    },
    data() {
        return {
            subscriptionAlert: false
        }
    },
    mounted() {
      if(SocketIO.getSocketInstance()) {
        SocketIO.getSocketInstance().on('subscription-alert', (err) => {
          console.log('subscrr err', err);
          this.subscriptionAlert = true;
        });
      }
    },
    beforeUnmount() {
        if(SocketIO.getSocketInstance()){
            socketIO.getSocketInstance().off('subscription-alert');
        }
    }

}


</script>