import Api from './Api'

export default {
    getNotifications(page = 1) {
        return Api().get(`/notifications?page=${page}`)
    },
    markAsSeen(id) {
        return Api().post('/notification/markAsSeen', id)
    },
    setDeviceToken(userId, token, deviceId, deviceInfo) {
        let data = {
            userId:userId,
            token:token
        };

        if(deviceInfo){
            if(deviceInfo.platform) {
                data.platform = deviceInfo.platform;
            }
            if(deviceInfo.os) {
                data.os = deviceInfo.os;
            }
            if(deviceInfo.model) {
                data.model = deviceInfo.model;
            }
            data.deviceInfo = deviceInfo;

        }

        if(typeof deviceId !== 'undefined') {
            data.deviceId = deviceId;
        }
        return Api().post('/notifications/setDeviceToken', data)
    },
    markAllAsSeen() {
        return Api().get('/notification/markAllAsSeen')
    }
}
