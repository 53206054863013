import {createApp} from 'vue'
import App from './App.vue'
import {store} from './store'
import router from './router'
import { VueHeadMixin, createHead } from '@unhead/vue';

import {plugin, defaultConfig, createInput} from '@formkit/vue'

import '@formkit/themes/genesis'
import { en, el, de, fr, zh } from '@formkit/i18n'

/* START Theme CSS */
import 'maz-ui/css/main.css'
// Bootstrap CSS 
import './assets/plugins/bootstrap/css/bootstrap.min.css'

//import bootstrap and owl carousel into vue
import './../public/plugins/owlcarousel/owl.carousel.min.js'
// Fontawesome CSS
import './assets/plugins/fontawesome/css/fontawesome.min.css'
import './assets/plugins/fontawesome/css/all.min.css'

// Owl Carousel CSS 
import './assets/plugins/owlcarousel/owl.carousel.min.css'
import './assets/plugins/owlcarousel/owl.theme.default.min.css'

// Aos CSS 
import './assets/plugins/aos/aos.css'

/* Import CSS styles */
import './assets/css/style.css'

/* Import Additional CSS styles */
import './assets/css/style-additional.css'

/* Import Vue Components CSS styles (overwrites)*/
import './assets/css/components.css'

/* Import Animate CSS styles */
import './assets/css/animate.min.css'

import firebaseMessaging from './plugins/firebase'

/* Import All CSS styles */
// import './assets/css/all.min.css'
/* END CSS */

import VueMobileDetection from "vue-mobile-detection";

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import InfiniteLoading from 'v3-infinite-loading'
import 'v3-infinite-loading/lib/style.css' //required if you're not going to override default slots

import i18n from './locales/index.js'

import Wizard from 'form-wizard-vue3'
import 'form-wizard-vue3/dist/form-wizard-vue3.css';

import Vue3TouchEvents from "vue3-touch-events";

import MazPhoneNumberInputFormKit from "./components/formkit/MazPhoneNumberInputFormKit.vue"

const customTelInput = createInput(MazPhoneNumberInputFormKit
    // {
    //     $cmp: MazPhoneNumberInputFormKit,
    //     props: {
    //         context: '$node.context'
    //     }
    // }
);

const validPhone = function (node) {
    return typeof node.props.isValid !== 'undefined' ? node.props.isValid : true;
}

//init head
const head = createHead();

// App Initialization
const app = createApp(App)
    .use(store)
    .use(router)
    .use(plugin, defaultConfig({
        inputs: {
            telInput:customTelInput
        },
        rules: {
            validPhone
        },
        locales:{en, el, de},
        locale: i18n.global.locale,
        messages: {
            en: {
                validation: {
                    validPhone: function(name) {
                        return `Phone number is invalid`
                    }
                }
            },
            el: {
                validation: {
                    required: function({name}){
                        return `Το πεδίο "${name}" είναι υποχρεωτικό`
                    },
                    validPhone: function(name) {
                        return `Το τηλέφωνο δεν είναι έγκυρο`
                    },
                    length({name, args: [first = 0, second = Infinity] }){
                        const min = Number(first) <= Number(second) ? first : second;
                        const max = Number(second) >= Number(first) ? second : first;
                        if (min == 1 && max === Infinity) {
                            /* <i18n case="Shown when the length of the user-provided value is not at least one character."> */
                            return `${name[0].toUpperCase() + name.substr(1)} πρέπει να είναι τουλάχιστον ενός χαρακτήρα.`
                            /* </i18n> */
                        }
                        if (min == 0 && max) {
                            /* <i18n case="Shown when first argument supplied to the rule is 0, and the user-provided value is longer than the max (the 2nd argument) supplied to the rule."> */
                            return `${name[0].toUpperCase() + name.substr(1)} πρέπει να είναι λιγότεροι ή ίσοι με ${max} χαρακτήρες.`
                            /* </i18n> */
                        }
                        if (min === max) {
                            /* <i18n case="Shown when first and second argument supplied to the rule are the same, and the user-provided value is not any of the arguments supplied to the rule."> */
                            return `Το ${name[0].toUpperCase() + name.substr(1)} θα πρέπει να έχει μήκος ${max} χαρακτήρες.`
                            /* </i18n> */
                        }
                        if (min && max === Infinity) {
                            /* <i18n case="Shown when the length of the user-provided value is less than the minimum supplied to the rule and there is no maximum supplied to the rule."> */
                            return `${name[0].toUpperCase() + name.substr(1)} πρέπει να είναι περισσότεροι ή ίσοι με ${min} χαρακτήρες.`
                            /* </i18n> */
                        }
                        /* <i18n case="Shown when the length of the user-provided value is between the two lengths supplied to the rule."> */
                        return `${name[0].toUpperCase() + name.substr(1)} πρέπει να είναι μεταξύ ${min} και ${max} χαρακτήρες.`
                        /* </i18n> */
                    },
                    confirm({ name }) {
                        /* <i18n case="Shown when the user-provided value does not equal the value of the matched input."> */
                        return `Το πεδίο "${name[0].toUpperCase() + name.substr(1)}" δεν έχει την ίδια τιμή.`
                        /* </i18n> */
                    },
                },
                ui: {
                    incomplete: 'Λυπούμαστε, υπάρχουν πεδία που δεν έχουν συμπληρωθεί σωστά.',
                }
            }
        }
    }))
    .use(VueMobileDetection)
    .use(i18n)
    .use(Vue3TouchEvents)
    .use(head)

//add mixins
app.mixin(VueHeadMixin);

app.component('EasyDataTable', Vue3EasyDataTable);
app.component('InfiniteLoading', InfiniteLoading);
app.component('Wizard', Wizard)

app.config.globalProperties.$messaging = firebaseMessaging;
app.mount('#app');